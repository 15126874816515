import React, { useEffect } from 'react'
import Layout from "../Componets/Layout";
import { Link, useNavigate } from 'react-router-dom';


export default function Account(p) {
    const navigate = useNavigate();
    const user = localStorage.getItem('name')
    const role = localStorage.getItem('role')
    const address = localStorage.getItem('address')
    const contact_no = localStorage.getItem('contact_no')
    const email = localStorage.getItem('email')

    // Check if role is retrieved correctly
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'SUPER_ADMIN') {
            navigate("/");
        }
    }, [navigate]);


    return (
        <Layout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/Home">Dashboard</Link></li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                    </nav>
                </div>
                {/* <!-- End Page Title --> */}

                <section className="section profile">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                    <img src="assets/img/profile-img.png" alt="Profile" className="rounded-circle" />
                                    <h2>{user}</h2>
                                    <h3>{role}</h3>
                                    <div className="social-links mt-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body pt-3">
                                    {/* <!-- Bordered Tabs --> */}
                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                            <h5 className="card-title">Profile Details</h5>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label ">Full Name</div>
                                                <div className="col-lg-9 col-md-8">{user}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Role</div>
                                                <div className="col-lg-9 col-md-8">{role}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Address</div>
                                                <div className="col-lg-9 col-md-8">{address}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Phone</div>
                                                <div className="col-lg-9 col-md-8">{contact_no}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Email</div>
                                                <div className="col-lg-9 col-md-8">{email}</div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                            {/* <!-- Profile Edit Form --> */}
                                        </div>
                                    </div>
                                    {/* <!-- End Bordered Tabs --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
