import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const AdminLayout = (props) => {
  // this is admin layout  component thet will be used in all admin pages 
  //  it will take the children props and render it in the main section 


  const [sidebarOpen, setSidebarOpen] = useState(true);
  const user = localStorage.getItem('name')
  const role = localStorage.getItem('role')

  return (
    <div>

      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}

        Heading='Admin '

        P1='Dashboard' R1='/AdminHome' i1='bi bi-grid'

        P2=' Manage Subject' R2='/ListSubject' i2='bi bi-journal-bookmark-fill'

        P3='Manage Faculty' R3='/ListFaculty' i3='bi bi-person-fill-gear'

        P4='Manage Exams' R4='/ListExam' i4='bi bi-journal-text'

        P5='LogOut' R5='/' i5='bi-box-arrow-left' f5={() => { window.localStorage.clear() }}

        h1='/AdminHome'

        user={user}
        role={role}


      />
      <main className={` main ${sidebarOpen ? "open" : "close"}`} >
        <div className="main-herder">
          {props.children}
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default AdminLayout;
