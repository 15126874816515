import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminLayout from '../Componets/AdminLayout';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';




export default function ListSection() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const { id, subjectId } = location.state;

    const [data, setData] = useState([]);
    // const [sectionInfo, setSectionInfo] = useState(null);
    const [searchText, setSearchText] = useState('');
    // Check if user role is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`${apiUrl}/api/section/${id}/${subjectId}`, {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                setData(res.data.sections);
                // setSectionInfo(res.data);
            })
            .catch(err => console.error(err));
    }, [id, subjectId, apiUrl]);

    // Function to calculate total marks and number of questions for a section
    const calculateSectionStats = (section) => {
        let totalMarks = 0;
        let numQuestions = section.questions.length;
        section.questions.forEach(question => {
            totalMarks += question.marks;
        });
        return { totalMarks, numQuestions };
    };

    // Function to handle section deletion
    const handleDeleteSection = (sectionId) => {
        // Function to handle section deletion
        alert('Are you sure you want to delete this section?');
        const token = localStorage.getItem('token');

        axios.delete(`${apiUrl}/api/section/${sectionId}`, {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                // Remove the deleted section from the state
                setData(data.filter(section => section.id !== sectionId));
                console.log('Section deleted successfully');
                toast.success("Section deleted successfully");
            })
            .catch(err =>
                toast.error(err.response.data.message));
    };



    // Define the columns for the data table
    const columns = [
        {
            name: 'No.',
            cell: (row, index) => index + 1,
            width: '50px',
            center: "true",
        },
        {
            name: 'Section Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Total Marks',
            cell: row => calculateSectionStats(row).totalMarks,
            width: '180px',
            center: "true",

        },
        {
            name: 'Number of Questions',
            cell: row => calculateSectionStats(row).numQuestions,
            width: '180px',
            center: "true",

        },
        {
            name: 'Actions',
            width: '100px',
            center: "true",
            cell: (row) => (
                <div>
                    <button className="btn btn-primary btn-sm mx-2" onClick={() => navigate('/EditSection', { state: { id: row } })} >
                        <i className="bi bi-pencil-square"  ></i>
                    </button>
                    <button className="btn btn-outline-danger btn-sm" onClick={() => handleDeleteSection(row.id)}><i className="bi bi-trash3-fill"></i></button>


                </div>
            )
        }
    ];

    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item " onClick={() => navigate(-1)}>ExamDetails</li>
                            <li className="breadcrumb-item active">List Section</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row">
                        <div className="">
                            <div className="card">
                                <div className="card-body mt-4 mb-1 mx-3">
                                    <div className="datatable-top">
                                        <div className="datatable-search">
                                            <input
                                                className="form-control"
                                                type="search"
                                                name="totalMarks"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                placeholder="Search"
                                                title="Search within table"
                                            />
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={() => navigate('/AddSection', { state: { id: id, subjectId: subjectId } })}><i className="bi bi-plus-square"></i> Add Section</button>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={data.filter(
                                            item =>
                                                item.name.toLowerCase().includes(searchText.toLowerCase())
                                        )}

                                        className="custom-table"
                                    />
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </AdminLayout>
    );
}
