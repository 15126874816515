import React from "react";
import { Link, } from "react-router-dom";
import Sidebar from "./Sidebar";
import "../App.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';

export default function Header(props) {
 
  return (
    <>
      {props.sidebarOpen ? <Sidebar
        P1={props.P1} R1={props.R1} I1={props.i1}
        P2={props.P2} R2={props.R2} I2={props.i2}
        P3={props.P3} R3={props.R3} I3={props.i3} f3={props.f3}
        P4={props.P4} R4={props.R4} I4={props.i4}
        P5={props.P5} R5={props.R5} I5={props.i5} f5={props.f5}
      /> : null}

      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link to={props.h1} className="logo d-flex align-items-center">
            {/* user profile img */}
            <img src="assets/img/logo.png" alt="" />
            <span className="d-none d-lg-block">{props.Heading}</span>
          </Link>
        </div>
        <i
          className="bi bi-list toggle-sidebar-btn"
          onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
        ></i>
        {/* <!-- End Logo --> */}

        {/* <!-- End Search Bar --> */}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            {/* <!-- End Search Icon--> */}
            <li className="nav-item dropdown pe-3">
              <Link
                to="/Profile"
                className="nav-link nav-profile d-flex align-items-center pe-0"
                data-bs-toggle="dropdown"
              >
                <img
                  src="assets/img/profile-img.png"
                  alt="Profile"
                  className="rounded-circle"
                />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {props.user}
                </span>
              </Link>

              {/* <!-- End Profile Iamge Icon --> */}
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{props.user}</h6>
                  <span>{props.role}</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to={"/"} onClick={() => window.localStorage.clear()}
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>

            </li>

          </ul>
        </nav>

      </header>
      {/* <!-- End Header --> */}
    </>
  );
}
