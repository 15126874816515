import React, { useEffect, useState } from 'react';
import AdminLayout from '../Componets/AdminLayout';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AllocationDetails() {
  const apiUrl = process.env.REACT_APP_API_URL; // Base URL for the API
  const [data, setData] = useState([]); // State to store the data from the API
  const [filteredData, setFilteredData] = useState([]); // State to store the filtered data for the table
  const [count, setCount] = useState(0); // State to store the total answer sheet count
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [selectedFacultyIds, setSelectedFacultyIds] = useState([]); // State to store selected faculty IDs
  const [newAllocatedCounts, setNewAllocatedCounts] = useState({}); // State to store new allocated counts for each faculty
  const [tableKey, setTableKey] = useState(Date.now()); // State to force reset the DataTable
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  const location = useLocation(); // Hook to access the current location
  const { id, subjectId } = location.state; // Destructure id and subjectId from the location state
  const navigate = useNavigate();// Use navigate hook from react-router-dom

  // cheking if the user is logged in or not then redirecting to home page
  useEffect(() => {
    const role = localStorage.getItem("role");

    if (role !== 'ADMIN') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);


  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/answersheet/allocate/${id}/${subjectId}`,
        {
          headers: {
            Authorization: token, // Set the authorization header
          },
        }
      );

      console.log(response.data); // Log the response data to check the structure
      const faculties = response.data.faculties || []; // Extract faculties array from the response
      setData(faculties); // Set the data state
      setFilteredData(faculties); // Set the filtered data state
      setCount(response.data.answerSheetCount); // Set the total answer sheet count state

      // Initialize new allocated counts from API response
      const initialNewAllocatedCounts = faculties.reduce((acc, faculty) => {
        acc[faculty.id] = faculty.notAllocatedCount || 0; // Assign API response value or 0
        return acc;
      }, {});
      setNewAllocatedCounts(initialNewAllocatedCounts);

      setLoading(false); // Set loading to false
    } catch (error) {
      console.error('Error fetching the data', error); // Log any errors
      setLoading(false); // Set loading to false
      toast.error(error.response?.data?.message || 'Error occurred'); // Check if the error message exists
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function
  }, [apiUrl, id, subjectId, token]); // Dependencies for the useEffect

  // Function to handle the allocation
  const handleAllocate = async () => {
    // Show a confirmation dialog before proceeding
    const isConfirmed = window.confirm(
      'Are you sure you want to allocate the answer sheets?'
    );

    if (isConfirmed) {
      try {
        const selectedFaculties = data.filter((faculty) =>
          selectedFacultyIds.includes(faculty.id)
        ); // Filter selected faculties
        const selectedFacultyIdsList = selectedFaculties.map(
          (faculty) => faculty.id
        ); // Extract the faculty IDs from the data state

        await axios.post(
          `${apiUrl}/api/answersheet/allocate/save`,
          {
            examId: id,
            subjectId: subjectId,
            facultyIds: selectedFacultyIdsList, // Use the extracted faculty IDs
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        toast.success('Answer sheet allocated successfully');
        fetchData(); // Refresh the data after successful allocation

        // Reset selection and new allocations
        setSelectedFacultyIds([]); // Clear selected faculty IDs
        setNewAllocatedCounts({});
        setTableKey(Date.now()); // Update tableKey to reset selection
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.response?.data?.message || 'Error occurred'); // Check if the error message exists
      }
    }
  };

  // Function to handle row selection and update allocation
  const handleRowSelected = (selectedRows) => {
    const ids = selectedRows.selectedRows.map((row) => row.id); // Extract the IDs of the selected rows
    setSelectedFacultyIds(ids); // Set the selected faculty IDs state

    // Update new allocated counts based on selection
    const updatedAllocatedCounts = { ...newAllocatedCounts };
    data.forEach((faculty) => {
      updatedAllocatedCounts[faculty.id] = ids.includes(faculty.id)
        ? faculty.notAllocatedCount
        : 0; // Set to the API response value or 0 based on selection
    });
    setNewAllocatedCounts(updatedAllocatedCounts);
  };

  // Define the columns for the DataTable
  const columns = [
    {
      name: 'No',
      selector: (row, index) => index + 1, // Add a serial number column
      width: '50px',
      center: true, // Correct the "center" property
    },
    {
      name: 'Faculty Name',
      selector: (row) => row.name, // Select the faculty name from the row data
      sortable: true,
    },
    {
      name: 'Allocated',
      selector: (row) => row.allocatedCount, // Select the answer sheet count from the row data
      center: true,
      width: '170px',
    },
    {
      name: 'New Allocated',
      selector: (row) => newAllocatedCounts[row.id] || 0, // Display API response or 0 if not selected
      center: true,
      width: '170px',
    },
  ];

  // Function to handle the search input change
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase(); // Get the search term and convert to lowercase
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm)
    ); // Filter the data based on the search term
    setFilteredData(filtered); // Set the filtered data state
  };

  return (
    <AdminLayout>
      <main id="main" className="main">
        <div className="pagetitle ">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item " onClick={() => navigate(-1)}>
                ExamDetails
              </li>
              <li className="breadcrumb-item active">
                AnswerSheet Allocation Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="card">
          <div className="card-body mt-5 mb-1 mx-3">
            <div className="datatable-top">
              <div className="datatable-search">
                <input
                  className="form-control"
                  type="search"
                  name="search"
                  placeholder="Search"
                  title="Search within table"
                  onChange={handleSearch} // Add the onChange handler
                />
              </div>
            </div>
            <DataTable
              key={tableKey} // Add the key to force reset
              columns={columns}
              selectableRows
              onSelectedRowsChange={handleRowSelected} // Add the onSelectedRowsChange handler
              data={filteredData} // Use filteredData for the table
              progressPending={loading}
              className="custom-table"
            />
            <div className="total-count-answersheet">
              <label className="bold">
                Total Answer Sheet Count: {count}
              </label>
            </div>
            <div className="text-end mt-3">
              <button
                type="button"
                onClick={handleAllocate}
                className="btn btn-primary mx-2"
              >
                <i className="bi bi-check2-circle"></i> Allocate
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate(-1)}
              >
                <i className="bi bi-x-circle"></i> Cancel
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
    </AdminLayout>
  );
}
