import React, { useEffect, useState } from "react";
import AdminLayout from "../Componets/AdminLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddExam() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    const [subjects, setSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/subject`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setSubjects(res.data.subjects);
            })
            .catch(err => console.log(err));
    }, [apiUrl]);

    const [formData, setFormData] = useState({
        name: '',
        isLocked: false,
        subjects: [{ subjectId: '', totalMarks: '', passingMarks: '' }]
    });

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        const token = localStorage.getItem('token');

        const selectedSubjects = formData.subjects.filter(subject => subject.subjectId !== '');

        const payload = {
            name: formData.name,
            isLocked: false,
            subjects: selectedSubjects.map(subject => ({
                subjectId: subject.subjectId,
                totalMarks: parseInt(subject.totalMarks),
                passingMarks: parseInt(subject.passingMarks)
            }))
        };


        axios.post(`${apiUrl}/api/exam`, payload, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setIsLoading(false);
                toast.success("Exam added successfully");
                setTimeout(() => navigate('/ListExam'), 3000);
                setFormData({
                    name: '',
                    subjects: [{ subjectId: '', totalMarks: '', passingMarks: '' }]
                });
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(err.response.data.message);
            });
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            subjects: prevState.subjects.map((subject, i) => i === index ? { ...subject, [name]: value } : subject)
        }));
    };

    const handleSubjectChange = (index, e) => {
        const { value } = e.target;
        const updatedSubjects = [...formData.subjects];
        updatedSubjects[index].subjectId = parseInt(value);
        setFormData(prevState => ({
            ...prevState,
            subjects: updatedSubjects
        }));
    };

    const addSubject = () => {
        setFormData(prevState => ({
            ...prevState,
            subjects: [...prevState.subjects, { subjectId: '', totalMarks: '', passingMarks: '' }]
        }));
    };

    const removeSubject = (index) => {
        setFormData(prevState => {
            const updatedSubjects = [...prevState.subjects];
            updatedSubjects.splice(index, 1);
            return {
                ...prevState,
                subjects: updatedSubjects
            };
        });
    };

    const getAvailableSubjects = (index) => {
        const selectedSubjectIds = formData.subjects.map(subject => subject.subjectId).filter(id => id !== '');
        return subjects.filter(subject => !selectedSubjectIds.includes(subject.id) || formData.subjects[index].subjectId === subject.id);
    };

    return (
        <>
            <AdminLayout>
                <main id="main" className="main">
                    <div className="pagetitle">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/AdminHome">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Add New Exams</li>
                            </ol>
                        </nav>
                    </div>

                    <section className="section col-lg-12">
                        <div className="row">
                            <div className="">
                                <div className="card">
                                    <div className="card-body mt-5 mb-1 mx-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className="container">
                                                <div className="container text-center mx-1">
                                                    <div className="row row-cols-1">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-floating mb-2">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="name"
                                                                            value={formData.name}
                                                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                                            placeholder="Name"
                                                                            required
                                                                        />
                                                                        <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col"></div>
                                                        <div className="col"></div>
                                                        <div className="col"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container mx-3">
                                                {formData.subjects.map((subject, index) => (
                                                    <div className="row row-cols-7" key={index}>
                                                        <div className="col-4">
                                                            <div className="form-floating mb-2">
                                                                <select className="form-select" value={subject.subjectId} onChange={(e) => handleSubjectChange(index, e)}>
                                                                    <option value="">Select Subject</option>
                                                                    {getAvailableSubjects(index).map(subject => (
                                                                        <option key={subject.id} value={subject.id}>{subject.name}</option>
                                                                    ))}
                                                                </select>
                                                                <label className="form-label" htmlFor="txtGrNo">Subject Name <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-floating mb-2">
                                                                <input className="form-control" type="text" name="totalMarks" value={subject.totalMarks} onChange={(e) => handleChange(e, index)} placeholder="Total Marks" required />
                                                                <label className="form-label" htmlFor="txtGrNo">Total Marks <span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-floating mb-2">
                                                                <input className="form-control" type="text" name="passingMarks" value={subject.passingMarks} onChange={(e) => handleChange(e, index)} placeholder="Passing Marks" required />
                                                                <label className="form-label" htmlFor="txtGrNo">Passing Marks<span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>

                                                        <div className="col mt-2 mx-3">
                                                            <button type="button" className="btn btn-danger" onClick={() => removeSubject(index)}><i className="bi bi-trash-fill"></i></button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="row text-end button-add-exam">
                                                <div className="col">
                                                    <button type="button" className="btn btn-primary justify-content-center" onClick={addSubject}>
                                                        <i className="bi bi-journal-plus"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row mx-3">
                                                <div className="col-sm-12 pt-4 text-end">
                                                    <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i> Back</button>
                                                    <button type="submit" className="btn btn-primary px-4 mx-2" disabled={isLoading}>
                                                        {isLoading ? 'Saving...' : 'Save'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </AdminLayout>
        </>
    );
}
