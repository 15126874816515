import React, { useEffect, useState } from 'react';
import AdminLayout from '../Componets/AdminLayout';  // Fixed typo: 'Componets' to 'Components'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default function SheetTransform() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const { id, subjectId } = location.state;
    const navigate = useNavigate();
    const [faculties, setFaculties] = useState([]);
    const [selectedFacultyTo, setSelectedFacultyTo] = useState();
    const [selectedFacultyFrom, setSelectedFacultyFrom] = useState();
    const [toDetails, setToDetails] = useState({});
    const [fromDetails, setFromDetails] = useState({});
    const [inProgressChecked, setInProgressChecked] = useState(false);
    const [transformDetails, setTransformDetails] = useState({});
    const [enable, setEnable] = useState(true);
    const [sheetCount, setSheetCount] = useState();


    useEffect(() => {
        const role = localStorage.getItem("role");

        if (role !== 'ADMIN') {
            navigate("/");
        }
        // eslint-disable-next-line
    }, [navigate]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`${apiUrl}/api/faculty/list/${subjectId}`, {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                setFaculties(res.data.facultys || []);
            })
            .catch(err => {
                console.error(err);
                setFaculties([]);
            });
    }, [apiUrl, subjectId]);

    const filteredFacultiesFrom = faculties.filter(faculty => faculty.id !== selectedFacultyTo);
    const filteredFacultiesTo = faculties.filter(faculty => faculty.id !== selectedFacultyFrom);

    const handleFacultyToChange = (e) => {
        if (e.target.value === "") {
            setToDetails({})
            setEnable(true);
        }
        const selectedId = parseInt(e.target.value, 10);
        setSelectedFacultyTo(selectedId);
        Sheetdetailsto(selectedId);
    };

    const handleFacultyFromChange = (e) => {
        if (e.target.value === "") {
            setFromDetails({});
            setEnable(true);

        }
        const selectedIdfrom = parseInt(e.target.value, 10);
        setSelectedFacultyFrom(selectedIdfrom);
        Sheetdetailsfrom(selectedIdfrom);
    };

    const Sheetdetailsto = (selectedId) => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/answersheet/answersheetstatus/${id}/${subjectId}/${selectedId}`, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                setToDetails(response.data.fromFaculty);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const Sheetdetailsfrom = (selectedIdfrom) => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/answersheet/answersheetstatus/${id}/${subjectId}/${selectedIdfrom}`, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                setFromDetails(response.data.fromFaculty);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const TransferCount = () => {
        const token = localStorage.getItem('token');
        let data = JSON.stringify({
            "examId": id,
            "subjectId": subjectId,
            "fromFacultyId":  selectedFacultyTo, // selectedFacultyTo is the id of the selected faculty left from the dropdown
            "toFacultyId":selectedFacultyFrom , // selectedFacultyFrom is the id of the selected faculty right from the dropdown
            "status": [
                "PENDING",
                ...(inProgressChecked ? ["INPROGRESS"] : [])
            ]
        });

        axios.post(`${apiUrl}/api/answersheet/transform`, data, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setTransformDetails(response.data);
                toast.info(`there are "${transferSheetCount}" answersheet  comes to transfer  but in them  "${response.data.sameIdCount}"same answersheet  therefore  it can't be transferred and  remaining answersheet you can transfer`);
                setEnable(false);


            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message);
            });
    };



    const TransferSheet = () => {
        
        const token = localStorage.getItem('token');
        let data = JSON.stringify({
            "examId": id,
            "subjectId": subjectId,
            "fromFacultyId": selectedFacultyTo,
            "toFacultyId":  selectedFacultyFrom,
            "status": [
                "PENDING",
                ...(inProgressChecked ? ["INPROGRESS"] : [])
            ],
            "transform": sheetCount || transformDetails.differentIdCount
        });

        axios.post(`${apiUrl}/api/answersheet/transformfaculty`, data, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
                
            }
        })
            .then((response) => {
                toast.success(`${response.data.updatedCount} answersheet transfered successfully`);
                Sheetdetailsto(selectedFacultyTo);
                Sheetdetailsfrom(selectedFacultyFrom);
                setTransformDetails({});
                setEnable(true);

            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message);
            });
    };
    // Compute the Transfer Sheet count value
    const transferSheetCount = inProgressChecked
        ? (toDetails.unchecked || 0) + (toDetails.inProgress || 0)
        : (toDetails.unchecked || 0);
        const handleCountChange = (event) => {
            setSheetCount(event.target.value )
          };
    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" onClick={() => navigate(-1)}>ExamDetails</li>
                            <li className="breadcrumb-item active">Sheet Transfer</li>
                        </ol>
                    </nav>
                </div>

                <section className="section ms-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card me-3 ms-4 pt-4">
                                <div className="card-body">
                                    <div className="d-flex flex-row mb-4 mt-3">
                                        <div className='col-7 ms-3 '>
                                            <div className="row mb-2 ">
                                                <div className="col-sm-8 pe-1">
                                                    <select
                                                        className="form-select p-2"
                                                        value={selectedFacultyTo}
                                                        onChange={handleFacultyToChange}
                                                        aria-label="Select faculty to"
                                                    >
                                                        <option value="">Select faculty to</option>
                                                        {filteredFacultiesTo.length > 0 ? filteredFacultiesTo.map(faculty => (
                                                            <option key={faculty.id} value={faculty.id}>
                                                                {faculty.name}
                                                            </option>
                                                        )) : <option>No Faculties Available</option>}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="container text-start">
                                                <div className="row ">
                                                    <div className="col-4 border p-2 ">Total:-</div>
                                                    <div className="col-4 border p-2">{toDetails.Total}</div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">Checked:-</div>
                                                    <div className="col-4 border p-2">{toDetails.checked}</div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">Unchecked:-</div>
                                                    <div className="col-4 border p-2">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                {toDetails.unchecked}
                                                            </div>

                                                            <div className="col-8 ">
                                                                <input className="form-check-input ms-2" type="checkbox" id="gridCheck2" defaultChecked disabled />
                                                                <label className="form-check-label mx-1" htmlFor="gridCheck2">Select</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">In Progress:-</div>
                                                    <div className="col-4 border p-2">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                {toDetails.inProgress}
                                                            </div>

                                                            <div className="col-8 ">
                                                                <input
                                                                    className="form-check-input ms-2"
                                                                    type="checkbox"
                                                                    id="inProgressCheck"
                                                                    checked={inProgressChecked}
                                                                    onChange={() => setInProgressChecked(!inProgressChecked)}
                                                                />
                                                                <label className="form-check-label mx-1" htmlFor="inProgressCheck">Select</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-7'>
                                            <div className="row mb-2">
                                                <div className="col-sm-8 pe-1 ">
                                                    <select
                                                        className="form-select p-2"
                                                        value={selectedFacultyFrom}
                                                        onChange={handleFacultyFromChange}
                                                        aria-label="Select faculty from"
                                                    >
                                                        <option value="">Select faculty from</option>
                                                        {filteredFacultiesFrom.length > 0 ? filteredFacultiesFrom.map(faculty => (
                                                            <option key={faculty.id} value={faculty.id}>
                                                                {faculty.name}
                                                            </option>
                                                        )) : <option>No Faculties Available</option>}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">Total:-</div>
                                                    <div className="col-4 border p-2">{fromDetails.Total}</div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">Checked:-</div>
                                                    <div className="col-4 border p-2">{fromDetails.checked}</div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">Unchecked:-</div>
                                                    <div className="col-4 border p-2">{fromDetails.unchecked}</div>
                                                </div>
                                            </div>
                                            <div className="container text-start">
                                                <div className="row">
                                                    <div className="col-4 border p-2">In Progress:-</div>
                                                    <div className="col-4 border p-2">{fromDetails.inProgress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="d-flex flex-row mb-3 justify-content-end">
                                        <div className="mt-2 pt-1  mx-2">
                                            <label>Total sheet Transfer :-</label>
                                        </div>
                                        <div className="mt-2 mx-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                defaultValue={transformDetails.differentIdCount}
                                                onChange={handleCountChange}
                                                disabled={enable}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <button type="button" className="btn btn-primary px-3 mx-2" onClick={TransferCount} >Ok</button>
                                        </div>
                                        <div className="mt-2">
                                            <button type="button" className="btn btn-primary px-3 ms-2" disabled={enable} onClick={TransferSheet} >Transfer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <ToastContainer />
        </AdminLayout>
    );
}