import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const UserLayot = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const user = localStorage.getItem('name')
  const role = localStorage.getItem('role')
  return (
    <div>
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}
        Heading='Faculty'

        P1='Dashboard' R1='/UserHome' i1='bi bi-grid'

        P2='AnswerSheet' R2='/AnswerSheet' i2='bi bi-journal-bookmark-fill'

        P3='' R3='' i3='' 

        P4='' R4='' i4=''

        P5='LogOut' R5='/' i5='bi-box-arrow-left' f5={() => { window.localStorage.clear() }}

        user={user}
        role={role}
        h1='/UserHome'

      />
          <main className={` main ${sidebarOpen ? "open" : "close"}`} >
        <div className="main-herder">
          {props.children}
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default UserLayot;
