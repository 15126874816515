import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Componets/Layout'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditAdmin() {
  const [value, setValue] = useState({
    name: '',
    contactNo: '',
    email: '',
    remarks: '',
    username: '',
    password: '',
    address: '',
    clientId: ''
  });
  const [clientName, setClientName] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'SUPER_ADMIN') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
  }, [navigate]);

  const location = useLocation();
  const { id } = location.state;

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${apiUrl}/api/admin/${id}`, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then(res => {
        setValue({
          name: res.data.name,
          contactNo: res.data.contactNo,
          email: res.data.email,
          remarks: res.data.remarks,
          username: res.data.username || '', // Handle empty username conditionally
          password: '', // Password is not provided in response, set it as empty initially
          address: res.data.address,

        });

        // Fetch client name using clientId
        return axios.get(`${apiUrl}/api/client/${res.data.clientId}`, {
          headers: {
            'Authorization': `${token}`
          }
        });
      })
      .then(clientRes => {
        setClientName(clientRes.data.name);
      })
      .catch(err => console.error(err));
  }, [id, apiUrl]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    axios.put(`${apiUrl}/api/admin/${id}`, value, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then(res => {
        toast.success("Admin Client information successfully updated");
        setTimeout(() => navigate('/ViewAdmins'), 3000);
      })
      .catch(err =>
        toast.error(err.response.data.message)
      );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(prevState => ({ ...prevState, [name]: value }));
  }

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle mb-4">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/Home">Dashboard</Link></li>
              <li className="breadcrumb-item"><Link to="/ViewAdmins">View Admins</Link></li>
              <li className="breadcrumb-item active">Edit Admin</li>
            </ol>
          </nav>
        </div>

        <section className="section col-lg-12">
          <div className="row">
            <div className="">
              <div className="card">
                <div className="card-body mt-5 mb-1 mx-3">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={value.name}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={value.email}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Email <span className="text-danger">*</span></label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="contactNo"
                            placeholder="Contact Number"
                            value={value.contactNo}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Contact Number <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={value.address}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Address<span className="text-danger">*</span></label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={value.username}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Username <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2" style={{ position: 'relative' }}>
                          <input
                            className="form-control"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                            value={value.password}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Password <span className="text-danger">*</span></label>
                          <button
                            className="btn btn mt-3"
                            type="button"
                            onClick={togglePasswordVisibility}
                            style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                          >
                            {showPassword ? (
                              <i className="bi bi-eye-slash"></i>
                            ) : (
                              <i className="bi bi-eye-fill"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="clientName"
                            placeholder="Client Name"
                            value={clientName}
                            disabled={true}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Client Name</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2">
                          <input
                            className="form-control"
                            type="text"
                            name="remarks"
                            placeholder="Remarks"
                            value={value.remarks}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="txtGrNo">Remarks</label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12 pt-4 text-end">
                        <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}>
                          <i className="bi bi-arrow-bar-left"></i> Back
                        </button>
                        <button type="submit" className="btn btn-primary px-4 mx-2">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
