import React, { useEffect, useState } from "react";
import AdminLayout from "../Componets/AdminLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddSubject() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false); // save button  loading  effect state

    useEffect(() => {
        const role = localStorage.getItem("role");
        if (role !== 'ADMIN') { // Adjusted comparison to match the role stored in localStorage
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    // State to hold form data
    const [formData, setFormData] = useState({
        name: '',
        subjectCode: '', // Ensure SubjectCode is handled correctly as a number
    });

    const handleSubmit = (e) => {
        setLoaded(true);
        e.preventDefault();

        const token = localStorage.getItem('token');

        // Make a POST request to add new subject
        axios.post(`${apiUrl}/api/subject`, formData, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                console.log("Subject added successfully:", res.data);

                // Display success toast and redirect to ListSubject page
                toast.success("Subject added successfully");
                setLoaded(false);
                setTimeout(() => navigate('/ListSubject'), 3000);

                // Reset form data to initial state
                setFormData({
                    name: '',
                    subjectCode: '', // Reset SubjectCode field as well
                });
            })
            .catch(err => {
                console.error("Error adding subject:", err);
                toast.error(err.response?.data?.message || "Error adding subject");
                setLoaded(false);
            });
    }

    // Function to handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Check if the input field is 'SubjectCode' and convert it to a number
        setFormData(prevState => ({
            ...prevState,
            [name]: name === "subjectCode" ? Number(value) : value
        }));
    }

    return (
        <>
            <AdminLayout>
                <main id="main" className="main">
                    <div className="pagetitle ">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Add New Subject</li>
                            </ol>
                        </nav>
                    </div>
                    {/* <!-- End Page Title --> */}
                    <section className="section col-lg-112">
                        <div className="row">
                            <div className="">
                                <div className="card">
                                    <div className="card-body mt-5 mx-3">
                                        {/* <!-- General Form Elements --> */}
                                        <form onSubmit={handleSubmit}>
                                            {/* Details of subject */}
                                            <div className="container">
                                                <div className="row row-cols-1">
                                                    <div className="col-6">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                                placeholder="Name"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="txtGrNo">Subject Name <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="subjectCode"
                                                                value={formData.subjectCode}
                                                                onChange={handleChange}
                                                                placeholder="Subject Code"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="txtGrNo">Subject Code <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Submit Button */}
                                            <div className="row">
                                                <div className="col-sm-12 pt-4 text-end">
                                                    <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}>
                                                        <i className="bi bi-arrow-bar-left"></i> Back
                                                    </button>

                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary px-4 mx-2"
                                                        disabled={loaded}
                                                    >
                                                        {loaded ? (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Wait...
                                                            </>
                                                        ) : (
                                                            <>
                                                                Save
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {/* <!-- End General Form Elements --> */}
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </AdminLayout>
        </>
    );
}
