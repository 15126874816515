import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../Componets/AdminLayout';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'


export default function ExamDetails() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate(); // Add this line to access the navigate function
    const location = useLocation(); // Add this line to access the location state
    const { id } = location.state;
    const [examDetails, setExamDetails] = useState({ name: '', subjects: [] });
    const [subjectList, setSubjectList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [subjectId, setSubjectId] = useState(''); // Ensure this is updated
    const [questionPaper, setQuestionPaper] = useState(null); // Ensure this is updated
    const [answerKey, setAnswerKey] = useState(null);// Ensure this is updated
    const [qpath, setQpath] = useState(''); // Ensure this is updated
    const [apath, setApath] = useState(''); // Ensure this is updated`
    const [qsnpaper, setQsnpaper] = useState(null); // select qsn paper
    const [asnkey, setAsnkey] = useState(null);// select answer key




    // Fetch the exam details and subject list when the component mounts and checks if user is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }

        const token = localStorage.getItem('token');
        const fetchExamDetails = axios.get(`${apiUrl}/api/exam/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        });

        const fetchSubjectList = axios.get(`${apiUrl}/api/subject`, {
            headers: {
                'Authorization': `${token}`
            }
        });

        Promise.all([fetchExamDetails, fetchSubjectList])
            .then(([examRes, subjectRes]) => {
                setExamDetails({
                    name: examRes.data.name,
                    subjects: examRes.data.subjects || []
                });
                setSubjectList(subjectRes.data.subjects || []);


            })
            .catch(err => console.error(err));
    }, [apiUrl, id, navigate]);

    const getSubjectNameById = (subjectId) => {
        const subject = subjectList.find(sub => sub.id === subjectId);
        return subject ? subject.name : 'Unknown';
    };

    const filteredSubjects = examDetails.subjects.filter(subject =>
        getSubjectNameById(subject.subjectId).toLowerCase().includes(searchQuery.toLowerCase())
    );
    //show path in modal on click
    const handleButtonClick = async (subjectId) => {
        // Fetch the question paper and answer key paths for the specified subjectId
        const token = localStorage.getItem('token');
        try {
            // Call the API
            const response = await axios.get(`${apiUrl}/api/exam/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            },

            );
            const examData = response.data;

            // Find the subject with the specified subjectId
            const subjects = examData.subjects.find(sub => sub.subjectId === subjectId);

            if (subjects) {
                // Log the questionPaperPath and answerKeyPath
                const questionPaperPath = subjects.questionPaperPath;
                const answerKeyPath = subjects.answerKeyPath;
                const quspath = questionPaperPath.split('/').pop();
                const anspath = answerKeyPath.split('/').pop();
                setQpath(quspath);
                setApath(anspath);

            } else {
                console.log('Subject not found');
            }
        } catch (error) {
            console.error('Error fetching exam data:', error);
        }
    };
    //clear path in modal
    const handleClear = () => {
        setQpath('');
        setApath('');
        setAnswerKey(null);
        setQuestionPaper(null);
        setQsnpaper(null);
        setAsnkey(null);
    };

    // Define the columns for the table 
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width: '50px',
            center: 'true'
        },
        {
            name: 'Subject Name',
            selector: (row) => getSubjectNameById(row.subjectId),
        },
        {
            name: 'Total Marks',
            selector: (row) => row.totalMarks,
            center: 'true',
            width: '120px',
        },
        {
            name: 'Passing Marks',
            selector: (row) => row.passingMarks,
            center: ' true',
            width: '120px',
        },

        {
            name: ' Actons',
            center: 'true',
            width: '300px',
            cell: (row) => (
                <div>
                    {/* 1 */}
                    <button onClick={() => navigate('/ListSection', { state: { id: id, subjectId: row.subjectId } })} className="btn btn-secondary btn-sm mx-1 " data-tooltip-id="my-tooltip" data-tooltip-content="List fo Section">
                        <i className="bi bi-list-columns"></i>
                    </button>
                    {/* 2 */}
                    <button
                        data-tooltip-id="my-tooltip" data-tooltip-content="Upload Question Paper & Answer Key"
                        className="btn btn-primary btn-sm mx-1"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"

                        onClick={() => {
                            setSubjectId(row.subjectId); // Set the subjectId here
                            handleButtonClick(row.subjectId); // Call the API and log paths
                        }}

                    >
                        <i className="bi bi-cloud-upload"></i>
                    </button>
                    {/* 3 */}
                    <button onClick={() => navigate('/AnswerSheetDetails', { state: { id: id, subjectId: row.subjectId } })} className="btn btn-primary btn-sm mx-1 " data-tooltip-id="my-tooltip" data-tooltip-content="AnswerSheet Details">
                        <i className="bi bi-qr-code-scan"></i>
                    </button>
                    {/* 4 */}
                    <button onClick={() => navigate('/AllocationDetails', { state: { id: id, subjectId: row.subjectId } })} className="btn btn-primary btn-sm mx-1" data-tooltip-id="my-tooltip" data-tooltip-content="AnswerSheet Allocation Details">
                        <i className="bi bi-diagram-3-fill"></i>
                    </button>
                    {/* 5 */}
                    <button onClick={() => navigate('/sheetTransfer', { state: { id: id, subjectId: row.subjectId } })} className="btn btn-primary btn-sm mx-1" data-tooltip-id="my-tooltip" data-tooltip-content="AnswerSheet Transform">
                        <i className="bi bi-recycle"></i>
                    </button>
                    {/* 6 */}
                    <button onClick={() => navigate('/Result', { state: { id: id, subjectId: row.subjectId } })} className="btn btn-primary btn-sm mx-1" data-tooltip-id="my-tooltip" data-tooltip-content="Result Details">
                        <i className="bi bi-clipboard2-data"></i>
                    </button>
                    <Tooltip id="my-tooltip" />
                </div>
            )
        }


    ];

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        if (name === 'questionpaper') {
            setQuestionPaper(files[0]);
            setQsnpaper(files[0].name);
        } else if (name === 'answerkey') {
            setAnswerKey(files[0]);
            setAsnkey(files[0].name);
        }
    };
    // Function to handle submit button click
    const handleSubmit = async (event) => {

        event.preventDefault();
        const formData = new FormData();
        formData.append('examId', id);
        formData.append('subjectId', subjectId); // Use the dynamic subjectId
        formData.append('questionpaper', questionPaper);
        formData.append('answerkey', answerKey);
        const token = localStorage.getItem('token');
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiUrl}/api/subject/questionpaperandanswerkey/upload`,
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        };
        try {
            const response = await axios(config);
            console.log(JSON.stringify(response.data));
            toast.success('Question paper and answer key uploaded successfully');
            setQsnpaper(null);
            setAsnkey(null);
            setAnswerKey(null);
            setQuestionPaper(null);
            closemodel();



        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message || error.response.data.errors?.[0]);

        }
    };


    const closemodel = () => {
        const closeButton = document.querySelector('#exampleModal .btn-close');
        if (closeButton) {
            closeButton.click();
        }
    };

    // Function to handle delete answerkey button click
    const deleteanswerkey = () => {
        if (window.confirm("Are you sure you want to delete this Answer Key?")) {

            const token = localStorage.getItem('token');
            const config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${apiUrl}/api/subject/answerkey/${id}/${subjectId}`,
                headers: {
                    'Authorization': `${token}`,
                }
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    toast.success("Answer key deleted successfully");
                    setApath('');
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Answer key not found");
                });
        };
    }
    // Function to handle delete questionpaper button click
    const deletequestionpaper = () => {
        if (window.confirm("Are you sure you want to delete this question paper?")) {

            const token = localStorage.getItem('token');
            const config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${apiUrl}/api/subject/questionpaper/${id}/${subjectId}`,
                headers: {
                    'Authorization': `${token}`,
                }
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    toast.success('Question paper deleted successfully');
                    setQpath('');

                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Question paper not found");
                });
        };

    }



    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active"> <Link to="/ListExam">View Exam</Link></li>
                            <li className="breadcrumb-item active">Exam Details</li>
                        </ol>
                    </nav>
                </div>

                <section className="section col-lg-12">
                    <div className="card">
                        <div className="card-body  mb-4 mx-3">
                            <h5 className="card-title text-center">{examDetails.name}</h5>
                            <div className="datatable-top">
                                <div className="datatable-search">
                                    <div className="datatable-search">
                                        <input className="form-control" type="text" name="totalMarks" placeholder="Search" title="Search within table" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                </div>
                                <div></div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={filteredSubjects}
                                className="custom-table"
                            />
                            <ToastContainer />
                        </div>

                    </div>
                </section>

                {/* <!-- Modal --> */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header bg-secondary">
                                <h5 className="modal-title  text-white p-1 rounded" id="exampleModalLabel">Upload Question Paper & Answer Key</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClear}></button>

                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit} className='popup-form'>
                                    <div className="container text-center">
                                        <div className="row">
                                            <div className="col">
                                                <div className="container">
                                                    <div className="row">
                                                        {/* <!-------------------- Upload Area for Question Paper --------------> */}
                                                        <div className="col">
                                                            <label className='mb-2'>Question Paper</label>
                                                            <div className="upload-area" onClick={() => document.getElementById('formFile-question').click()}>
                                                                <div className="icon">
                                                                    <img src="https://cdn-icons-png.freepik.com/512/16001/16001736.png" alt="A cat" />
                                                                </div>
                                                                <p className="text"> {qsnpaper ? qsnpaper : "Click to upload Question paper"} </p>
                                                                <input className="form-control " name="questionpaper" type="file" id="formFile-question" onChange={handleFileChange} style={{ display: 'none' }} />
                                                                <span >{qpath}</span>
                                                            </div>
                                                        </div>

                                                        {/* ------------------------upload Area for Answer Key---------------------------- */}
                                                        <div className="col">
                                                            <label className='mb-2'>Answer Key</label>
                                                            <div className="upload-area" onClick={() => document.getElementById('formFile-answer').click()}>
                                                                <div className="icon">
                                                                    <img src="https://cdn-icons-png.freepik.com/512/16001/16001736.png" alt="A cat" />
                                                                </div>
                                                                <p className="text">{asnkey ? asnkey : "Click to upload Answer key"}</p>
                                                                <input className="form-control" name="answerkey" type="file" id="formFile-answer" onChange={handleFileChange} style={{ display: 'none' }} />
                                                                <span>{apath}</span>

                                                            </div>
                                                        </div>
                                                        <div className="w-100"></div>
                                                        <div className="col"><button type='button' className='btn btn-outline-danger  mt-3 px-4' onClick={deletequestionpaper}><i className="bi bi-trash-fill"></i></button></div>
                                                        <div className="col"><button type='button' className='btn btn-outline-danger  mt-3 px-4' onClick={deleteanswerkey}><i className="bi bi-trash-fill"></i></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='modal-footer '>
                                        <button className='btn btn-primary ' type="submit">Upload</button>
                                        <button type="button" className="btn btn-secondary  mx-1" data-bs-dismiss="modal" onClick={handleClear} >Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/*   this is for path for answer key */}
        </AdminLayout>
    );
}
