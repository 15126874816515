import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import AdminLayout from "../Componets/AdminLayout";
import { ToastContainer, toast } from 'react-toastify';




export default function AddSection() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const [loaded, setLoaded] = useState(false); // save button  loading  efect deta  hold state 
    const location = useLocation();
    const { id, subjectId } = location.state; // Extract the exam ID and subject ID from
    const navigate = useNavigate(); // Use navigate hook from react-router-dom


    // Check if user role is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    // State for the section name and rows of questions
    const [examName, setExamName] = useState('');
    const [rows, setRows] = useState([{ no: '', question: '', orQuestion: '', marks: '' }]);

    // Handle form submission
    const handleSubmit = (e) => {
        setLoaded(true);
        e.preventDefault(); // Prevent default form submission
        const token = localStorage.getItem('token'); // Get the token from local storage
        const data = {
            name: examName,
            subjectId: parseInt(subjectId, 10), // Convert subjectId to a number
            examId: parseInt(id, 10), // Convert examId to a number
            questions: rows.map(row => ({
                no: row.no, // Set the question number as provided by the user
                text: row.question,
                text2: row.orQuestion,
                marks: parseInt(row.marks, 10) // Convert marks to a number
            }))
        };
        // Send POST request to the API to add a section
        axios.post(`${apiUrl}/api/section`, data, {
            headers: {
                'Authorization': token
            }
        })
            .then(response => {
                console.log('Section added successfully:', response.data);
                // Optionally navigate to another page or show a success message
                toast.success("Section added successfully");
                setLoaded(false);
                setTimeout(() => navigate(-1), 3000);

                // Reset the form fields after successful submission
                setExamName('');

                setRows([{ no: '', question: '', orQuestion: '', marks: '' }]);
            })
            .catch(error => {
                console.error('Error adding section:', error);
                toast.error(error.response.data.message);
                setLoaded(false);
            });
    }

    // Handle changes in the exam name input
    const handleChange = (e) => {
        setExamName(e.target.value);
    }

    // Handle changes in the rows of questions
    const handleRowChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    }

    // Add a new row for a question
    const addRow = () => {
        setRows([...rows, { no: '', question: '', orQuestion: '', marks: '' }]);
    }

    // Delete a specific row
    const deleteRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    }
    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item " onClick={() => navigate(-2)}>ExamDetails</li>
                            <li className="breadcrumb-item " onClick={() => navigate(-1)}>List Section</li>
                            <li className="breadcrumb-item active">Add Section</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row">
                        <div className="">
                            <div className="card">
                                <div className="card-body mt-5 mb-1 mx-3">
                                    <form onSubmit={handleSubmit}>
                                        <div className="container text-center px-1">
                                            <div className="row row-cols-12 mb-4">
                                                <div className="col-1"></div>
                                                <div className="col-8">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" name="examName" value={examName} onChange={handleChange} placeholder="Exam Name" required />
                                                        <label className="form-label">Section Name <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-2 mx-1">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="number" name="totalMarks" disabled={true} value={rows.reduce((acc, row) => acc + parseInt(row.marks || 0), 0)} readOnly placeholder="Total Marks" required />
                                                        <label className="form-label">Total <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-1"></div>
                                            </div>
                                        </div>
                                        <hr style={{ width: '104%', marginLeft: '-2%' }} />

                                        {rows.map((row, index) => (
                                            <div key={index} className="row mb-2">
                                                <div className="container text-center">
                                                    <div className="row justify-content-md-center">
                                                        <div className="container">
                                                            <div className="row row-cols-4">
                                                                <div className="col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" type="text" name="no" value={row.no} onChange={(e) => handleRowChange(e, index)} placeholder="No" required />
                                                                        <label className="form-label">No<span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-8">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" type="text" name="question" value={row.question} onChange={(e) => handleRowChange(e, index)} placeholder="Question" required />
                                                                        <label className="form-label">Question<span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" type="number" name="marks" value={row.marks} onChange={(e) => handleRowChange(e, index)} placeholder="Marks" required />
                                                                        <label className="form-label">Marks<span className="text-danger">*</span></label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-1">
                                                                    <div className="col-sm mt-2">
                                                                        <button type="button" className="btn btn-danger" onClick={() => deleteRow(index)}><i className="bi bi-trash-fill"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container">
                                                    <div className="row row-cols-4 mt-3">
                                                        <div className="col-1"></div>
                                                        <div className="col-8">
                                                            <div className="form-floating">
                                                                <input className="form-control" type="text" name="orQuestion" value={row.orQuestion} onChange={(e) => handleRowChange(e, index)} placeholder="OR Question" />
                                                                <label className="form-label">OR Question</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-2"></div>
                                                    </div>
                                                </div>
                                                <hr style={{ width: '104%', marginLeft: '1px', marginTop: '10px' }} />
                                            </div>
                                        ))}

                                        <div className="row mb-3">
                                            <div className="col text-end">
                                                <button type="button" className="btn btn-primary justify-content-center" onClick={addRow}>
                                                    <i className="bi bi-journal-plus"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col mx-2 text-end">
                                                <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>
                                                {/* <button type="submit" className="btn btn-primary  px-4 mx-2">Save</button> */}

                                                <button
                                                    type="submit"
                                                    className="btn btn-primary  px-4 mx-2"

                                                    disabled={loaded}
                                                >
                                                    {loaded ? (
                                                        <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> wait...</>
                                                    ) : (
                                                        <>
                                                            Save
                                                        </>
                                                    )}
                                                </button>


                                            </div>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </AdminLayout>
    );
}
