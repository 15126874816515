import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AdminLayout from '../Componets/AdminLayout';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';

export default function AnswerSheetDetails() {
  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file

  const [barcodes, setBarcodes] = useState([]); // State to hold barcodes
  const [responseData, setResponseData] = useState([]); // State to hold response data for table
  const [searchTerm, setSearchTerm] = useState(''); // State to hold search term
  const [showDeleted, setShowDeleted] = useState(false); // State to track if the switch is on or off
  const location = useLocation();
  const { id, subjectId } = location.state; // Get id and subjectId from location state
  const [history, setHistory] = useState([]);


  const navigate = useNavigate(); // Use navigate hook from react-router-dom

  // cheking if the user is logged in or not then redirecting to home page
  useEffect(() => {
    const role = localStorage.getItem("role");

    if (role !== 'ADMIN') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      // Axios configuration for fetching path
      let fetchPathConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/answersheet/path/${id}/${subjectId}`,
        headers: {
          'Authorization': `${token}`
        }
      };

      // Fetch path
      const pathResponse = await axios.request(fetchPathConfig);
      const fetchedPath = pathResponse.data.path;
      // Data payload for saving path
      const data = {
        "examId": id,
        "subjectId": subjectId,
        "answerSheetPath": fetchedPath
      };

      // Axios configuration for saving path
      let savePathConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/answersheet/path/save`,
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        },
        data: data // Include the data payload
      };

      // Save path and get barcode data
      const savePathResponse = await axios.request(savePathConfig);


      const barcodesData = savePathResponse.data.barcodesWithImageCounts;
      if (Array.isArray(barcodesData)) {
        const collectedBarcodes = barcodesData.map(item => item.barcode); // Collect all barcodes
        setBarcodes(collectedBarcodes); // Set barcodes state
        setResponseData(barcodesData); // Set response data for table
        toast.success("Barcode details displayed successfully");
      } else {
        console.log("No barcodes found.");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'Error fetching or saving path');
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, subjectId, apiUrl]); // Dependencies array

  // Define columns for DataTable
  const columns = [
    {
      name: 'No.',
      cell: (row, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: getStatusColor(row.status),
              marginRight: '8px',
            }}
          ></span>
          {index + 1}
        </div>
      ),
      width: '100px',
      center: "true",
    },
    {
      name: 'Barcode',
      width: '100px',
      center: "true",
      cell: (row) => (
        <div className="pt-2 fs-4 mb-2" id="table-btn ">
          <i className="bi bi-upc-scan"></i>
        </div>
      )
    },
    {
      name: 'Barcode Number',
      selector: row => row.barcode,
      sortable: true,
      center: "true",
    },
    {
      name: 'Number of Images',
      selector: row => row.noOfImages,
      sortable: true,
      center: "true",
    },
  ];

  // Handle saving barcodes
  const handleSaveBarcode = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      // Data payload
      const data = {
        "examId": id,
        "subjectId": subjectId,
        "barcodeIds": barcodes // Use the collected barcodes array
      };

      // Axios configuration
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/answersheet/barcode/save`,
        headers: {
          'Authorization': `${token}`,
        },
        data: data // Include the data payload
      };

      // Axios request
      await axios.request(config);
      toast.success("Barcode details saved successfully");
      fetchData(); // Re-fetch data after saving
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };


  // Filter data based on search term
  const filteredData = responseData.filter(item =>
    item.barcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.noOfImages.toString().includes(searchTerm)
  );

  // Helper function to determine the color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case 'Uploaded':
        return 'green';
      case 'NotUploaded':
        return 'red';
      case 'RecentlyUploaded':
        return 'orange';
      case 'Deleted':
        return 'gray';

      default:
        return 'purple';// Default color if status is unknown
    }
  };



  // Toggle the switch and fetch deleted barcodes if needed
  const handleToggleSwitch = () => {
    setShowDeleted(!showDeleted);

    if (!showDeleted) {
      historyofBarcodes();
    } else {

    }
  };

  const historyofBarcodes = () => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    // Assuming 'id' and 'subjectId' are defined somewhere in your component
    let data = {
      examId: id, // Ensure 'id' is defined in your component scope
      subjectId: subjectId // Ensure 'subjectId' is defined in your component scope
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/get-deleted-barcodes`,
      headers: {
        Authorization: `${token}` // Use Bearer token if necessary
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        try {
          const { deletedBarcodes } = response.data; // Destructure response

          toast.success(" Deleted Barcode details");
          setHistory(deletedBarcodes);
        } catch (error) {
          console.error('Error processing response data:', error);
          toast.error(error.response.data.message);
        }
      })
      .catch((error) => {

        console.error('Request failed:', error);
      });
  };


  const columns1 = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: '50px',
      center: "true",
    },
    {
      name: 'Deleted Barcode',
      selector: row => row.barcode,
      sortable: true,
    },
    {
      name: 'Deleted Date',
      selector: row => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: 'Deleted Time',
      selector: row => formattime(row.createdAt),
      sortable: true,
    },
    {
      name: 'Deleted BY',
      selector: row => row.updatedByName,
      sortable: true,
    },
  ]
  const formatDate = (dateString) => {
    // Check if dateString is valid
    if (!dateString || isNaN(Date.parse(dateString))) {
      return 'Invalid Date'; // Return a default string if the date is invalid
    }

    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
  };

  const formattime = (dateString) => {
    // Check if dateString is valid
    if (!dateString || isNaN(Date.parse(dateString))) {
      return 'Invalid Time'; // Return a default string if the date is invalid
    }

    const date = new Date(dateString);
    return format(date, 'hh:mm a');
  };


  return (
    <main id="main" className="main">
      <AdminLayout>
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item" onClick={() => navigate(-1)}>ExamDetails</li>
              <li className="breadcrumb-item active">AnswerSheetDetails</li>
            </ol>
            <div className="form-check form-switch text-end switch-div" >
              <input
                className="form-check-input position-absolute switch"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={showDeleted} // Bind the switch state
                onChange={handleToggleSwitch} // Toggle function
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Deleted</label>
            </div>
          </nav>
        </div>

        <div className='container mt-4'>
          <div className="row">
            <div className="col-lg-16">
              <div className="card">

                <div className="card-body mt-4 mb-1 mx-3">
                  {showDeleted ? ( // Conditional rendering based on switch state
                    <div>
                      <div className="datatable-top">
                        <div className="datatable-search">
                          <div className="datatable-search ">
                            <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns1}
                          data={history}
                          className="custom-table"
                        />

                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="datatable-top">
                        <div className="datatable-search">
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                        <div className="status-description">
                          <p>
                            <span style={{ color: 'green' }}>●</span> Uploaded | <span style={{ color: 'red' }}>●</span> Not Uploaded | <span style={{ color: 'orange' }}>●</span> Recently Uploaded | <span style={{ color: 'gray' }}>● </span>Deleted
                          </p>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns}
                          data={filteredData}
                          className="custom-table"
                        />

                        <div className="text-end">
                          <button type="button" className="btn btn-primary mt-2" onClick={handleSaveBarcode}>
                            <i className="bi bi-folder-check"></i> Save
                          </button>
                          <button type="button" className="btn btn-primary mt-2 mx-3" onClick={fetchData}>
                            <i className="bi bi-arrow-clockwise"></i> Refresh
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </AdminLayout>
    </main>
  );
}
