import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../Componets/AdminLayout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function EditSubject() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') { // Adjusted comparison to match the role stored in localStorage
            navigate("/");
        }

        // eslint-disable-next-line
    }, []);
    const location = useLocation();
    const { id } = location.state;
    const [value, setValue] = useState({
        name: '',
        subjectCode: ''
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/subject/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setValue({
                    name: res.data.name,
                    subjectCode: res.data.subjectCode
                });
            })
            .catch(err => console.error(err));
        // eslint-disable-next-line
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
    
        axios.put(`${apiUrl}/api/subject/${id}`, {
            ...value,
            subjectCode: Number(value.subjectCode) // Ensure subjectCode is converted to a number
        }, {
            headers: {
                'Authorization': `${token}`
            }
        })
        .then(res => {
            console.log(res);
            toast.success("Change Subject information successfully");
            setTimeout(() => navigate(-1), 3000);
        })
        .catch(err => 
            toast.error(err.response.data.message)
        );
    }
    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle ">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to="/ListSubject">View Subject</Link></li>
                            <li className="breadcrumb-item active">Edit Subject</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row ">
                        <div className="">
                            <div className="card">
                                <div className="card-body pt-5">
                                    {/* <!-- General Form Elements --> */}
                                    <form onSubmit={handleSubmit} >
                                        {/* Detils of clinent */}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.name} onChange={(e) => setValue({ ...value, name: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Subject Name <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.subjectCode} onChange={(e) => setValue({ ...value, subjectCode: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Subject code <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12 pt-4 text-end">
                                                <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>
                                                <button type="submit" className="btn btn-primary  px-4 mx-2" >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <ToastContainer limit={1} />
                                    {/* <!-- End General Form Elements --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </AdminLayout>
    );
}
