import React, { useEffect, useState } from 'react';
import UserLayout from '../Componets/UserLayout';
import DataTable from 'react-data-table-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs';  // Import dayjs for date formatting




export default function AllocateSheets() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);  // State to hold the API response data
    const [searchText, setSearchText] = useState("");  // State to hold the search text
    const apiUrl = process.env.REACT_APP_API_URL;  // Retrieve the API URL from environment variables
    const location = useLocation();
    const { id, subjectId } = location.state; // Extract the exam ID and subject ID from location state

    // Checking if user is logged in, if not then redirect to login page
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'USER') {
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    // Fetch data from API  
    useEffect(() => {
        const token = localStorage.getItem('token');
        async function FechDataWithAxios() {
            try {
                const response = await axios.get(`${apiUrl}/api/answersheet/barcode/detail/${id}/${subjectId}`, {
                    headers: {
                        'Authorization': `${token}`
                    }
                });
                console.log(response.data); // Log the response data to check the structure
                setData(response.data); // Update the state with the fetched data

                toast.success("Allocate sheet are seen successfully you can see them in allocate sheet section");
            } catch (error) {
                console.error('Error fetching the data', error); // Log any errors
                toast.error(error.response.data.message); // Check if the error message exists
            }
        }

        FechDataWithAxios(); // Call the function when the component mounts
        // eslint-disable-next-line    
    }, [apiUrl]);



    // Function to format date in my colome deta set for table
    const formatDate = (dateString) => {
        if (!dateString) {
            return '--'; // Return a default value or placeholder when dateString is null or undefined
        }

        return dayjs(dateString).format('DD/MM/YYYY');
    };

    // Filter data based on search text
    const filteredData = data.filter(item =>
        item.barcode.toLowerCase().includes(searchText.toLowerCase())
    );
    // Define columns
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width: '50px ',
            center: "true"
        },
        {
            name: 'Barcode',
            selector: row => row.barcode,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => formatDate(row.startDate),  // Use the formatDate function ,
            width: '100px',
        },
        {
            name: 'End Date',
            selector: row => formatDate(row.endDate),  // Use the formatDate function ,
            width: '90px',
        },
        {
            name: 'Marks',
            selector: row => `${row.obtainedMarks}/${row.totalMarks}`,
            width: '80px',
        },
        {
            name: 'Status',
            width: '70px',
            center: 'true',
            cell: (row) => (
                <div className="pt-0" id="table-btn">
                    {row.status === 'COMPLETED' && <span className="badge text-bg-success">completed</span>}
                    {row.status === 'INPROGRESS' && <span className="badge text-bg-warning">In Process</span>}
                    {row.status === 'PENDING' && <span className="badge text-bg-secondary">panding</span>}
                </div>
            ),
        },
        {
            name: 'Details',
            width: '80px',
            center: 'true',
            cell: (row) => (

                <div>
                    <button className="btn btn-secondary btn-sm " onClick={() => navigate('/ViewAnswerSheet', { state: { id: id, subjectId: subjectId, barcode: row.barcode, barcodeId: row.barcodeId } })}>
                        <i className="bi bi-list-columns"></i>
                    </button>
                </div>
            ),
        },
    ];

    return (
        <UserLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">

                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/UserHome">Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to="/AnswerSheet">Exam Details</Link></li>
                            <li className="breadcrumb-item active">Allocate Sheets</li>
                        </ol>
                    </nav>
                </div>
                <div className="card">
                    <div className="card-body mt-5 mb-1 mx-3">
                        <div className="datatable-top">
                            <div className="datatable-search">
                                <input
                                    className="form-control"
                                    type="search"
                                    name="search"
                                    placeholder="Search"
                                    title="Search within table"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)} // Update search text on change
                                />
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredData} // Use filtered data for the table
                            pagination
                            className="custom-table"
                        />
                        <ToastContainer limit={1} />
                    </div>
                </div>
            </main>
        </UserLayout>
    );
}