import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../Componets/AdminLayout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';



export default function EditFaculty() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate(); // useNavigate hook from react-router-dom
    const location = useLocation(); // useLocation hook from react-router-dom
    const { id } = location.state;
    const animatedComponents = makeAnimated(); // Enable animations for the select dropdown

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        username: '',
        subjectIds: [], // Initialize as an empty array for multi-select
        password: '',
        remarks: '',
        address: '',
    });

    const [subjectOptions, setSubjectOptions] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/faculty/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setFormData({
                    name: res.data.name,
                    email: res.data.email,
                    contactNo: res.data.contactNo,
                    username: res.data.username,
                    password: res.data.password,
                    remarks: res.data.remarks,
                    subjectIds: res.data.facultySubjects.map(subject => subject.subject.id), // Store only subject IDs
                    address: res.data.address
                });
            })
            .catch(err => console.error(err));

        // Fetch list of subjects
        axios.get(`${apiUrl}/api/subject`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setSubjectOptions(res.data.subjects.map(subject => ({
                    value: subject.id,
                    label: subject.name
                }))); // Transform subjects to options format
            })
            .catch(err => console.error(err));
    }, [id, apiUrl]);

    const handleChange = (selectedOptions, { name }) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: selectedOptions.map(option => option.value) // Map selected options to IDs
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        axios.put(`${apiUrl}/api/faculty/${id}`, formData, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                console.log(res);

                toast.success("Change faculty information successfully");
                setTimeout(() => navigate(-1), 3000);
            })
            .catch(err =>
                toast.error(err.response.data.message),

            );
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };



    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to="/ListFaculty">View faculty</Link></li>
                            <li className="breadcrumb-item active">Edit faculty</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row">
                        <div className="">
                            <div className="card">
                                <div className="card-body pt-5">

                                    <form onSubmit={handleSubmit}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Email <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Contact No" value={formData.contactNo} onChange={(e) => setFormData({ ...formData, contactNo: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Contact No <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Address" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Address <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Username" value={formData.username} onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Username <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type={showPassword ? 'text' : 'password'} placeholder="Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Password <span className="text-danger">*</span></label>
                                                        <button
                                                            className="btn btn mt-3 "
                                                            type="button"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                                                        >
                                                            {showPassword ? (
                                                                <i className="bi bi-eye-slash"></i>
                                                            ) : (
                                                                <i className="bi bi-eye"></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        {/* multi select options here  */}
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            components={animatedComponents}
                                                            placeholder="Select Subjects"
                                                            isMulti
                                                            name="subjectIds"
                                                            options={subjectOptions}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(selectedOptions) => handleChange(selectedOptions, { name: 'subjectIds' })}
                                                            value={subjectOptions.filter(option => formData.subjectIds.includes(option.value))}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Add other input fields here */}
                                                <div className="col">
                                                    <div className="form-floating mb-2">
                                                        <input className="form-control" type="text" placeholder="Remarks" value={formData.remarks} onChange={(e) => setFormData({ ...formData, remarks: e.target.value })} />
                                                        <label className="form-label" htmlFor="txtGrNo">Remarks</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12 pt-4 text-end">
                                                <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>
                                                <button type="submit" className="btn btn-primary  px-4 mx-2">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <ToastContainer limit={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </AdminLayout>
    );
}

