import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  const user = localStorage.getItem('name')
  const role = localStorage.getItem('role')

  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div>
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}
        Heading='SuperAdmin'

        P1='Dashboard' R1='/Home' i1='bi bi-grid'


        P2='Manage Clients' R2='/ViewClient' i2='bi bi-person-fill-gear'


        P3='Manage Admins' R3='/ViewAdmins' i3='bi bi-person-fill-add'


        P4='Profile' R4='/Account' i4='bi bi-person-circle'

        P5='LogOut' R5='/' i5=' bi-box-arrow-left' f5={() => { window.localStorage.clear() }}

        user={user}
        role={role}
        h1='/Home'


      />
        <main className={` main ${sidebarOpen ? "open" : "close"}`} >
        <div className="main-herder">
          {props.children}
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
