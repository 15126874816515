import React from 'react'

export default function Footer() {
  return (
    <div>
      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" className="footer">
         <div className="copyright">
      &copy; Copyright <strong><span> 2023-2024 A.C.P.</span></strong> All Rights Reserved Designed by Darshan Talaviya

     </div>  

      </footer>

    </div>
  )
}
