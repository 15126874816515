import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loaded, setLoaded] = useState(false); //  button  loading  efect deta  hold state 

  const handleUsername = (e) => {
    setUsername(e.target.value);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleApi = (e) => {
    setLoaded(true);

    e.preventDefault(); // Prevent default form submission behavior
    axios.post(`${apiUrl}/api/auth/login`, {
      username: username,
      password: password
    }).then(result => {
      setLoaded(false);
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('role', result.data.user.role);
      localStorage.setItem('name', result.data.user.name);
      localStorage.setItem('address', result.data.user.address);
      localStorage.setItem('contact_no', result.data.user.contactNo);
      localStorage.setItem('email', result.data.user.email);
      localStorage.setItem('clientId', result.data.user.clientId);
      localStorage.setItem('facultyid', result.data.user.id);

      if (result.data.user.role === 'SUPER_ADMIN') {
        navigate('/Home');
      } else if (result.data.user.role === 'ADMIN') {
        navigate('/AdminHome');
      } else if (result.data.user.role === 'USER') {
        navigate('/UserHome');
      }
    }).catch(error => {
      setLoaded(false);
      if (!error.response) {
        toast.error('Pliease check your network connection, server not responding');
      } else {
        toast.error(error.response.data.message);
        console.log(error);
      }

    });
  }

  return (
    <main>
      <div className="" id='Login-page'>
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">A.C.P.</span>
                  </a>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p className="text-center small">Enter your username & password to login</p>
                    </div>
                    <form onSubmit={handleApi}> {/* Wrap the form around input fields and button */}
                      <div className="row g-3 ">
                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">Username</label>
                          <div className="input-group has-validation">

                            <input className="form-control" value={username} onChange={handleUsername} type="text" required />
                            <div className="invalid-feedback">Please enter your username.</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">Password</label>
                          <div className="input-group has-validation">
                            <input className="form-control" value={password} onChange={handlePassword} type={showPassword ? 'text' : 'password'} required />
                            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash"></i>
                              )}
                            </button>
                            <div className="invalid-feedback">Please enter your password!</div>
                          </div>

                        </div>
                        <div className="col-12">
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            disabled={loaded}
                          >
                            {loaded ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait...</>
                            ) : (
                              <>
                                Login
                              </>
                            )}
                          </button>
                        </div>
                        <ToastContainer />
                        <div className="col-12">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="credits">
                  Designed by <Link to='https://www.linkedin.com/in/darshan-talaviya-a97677278?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ' target="_blank">Darshan Talaviya </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Login;
