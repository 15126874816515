import { useNavigate } from "react-router-dom";
import "../App.css";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserLayot from "../Componets/UserLayout";
export default function Home(p) {
  const navigate = useNavigate();
  //check for login or not
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'USER') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  //  alert function to show alert when login successfully
  useEffect(() => {
    if (!localStorage.getItem('alertShown')) {
      // Show the alert 
      notify();
      // Set a flag in local storage to indicate that the alert has been shown
      localStorage.setItem('alertShown', 'true');
    }
    // eslint-disable-next-line
  }, []);

  const notify = () => toast.success("Login successfully");

  return (
    <UserLayot >
      <main id="main" className="main">
        <div className="pagetitle mb-4">
          <h1>Dashboard</h1>

        </div>

        {/* ------------------------------------------  Page  ---------------------------- */}

        <section className="section dashboard">
          <div className="row">
            {/* ----------------------------- Allocated AnswerSheet Card --------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/AnswerSheet")}
                  >
                    Allocated AnswerSheet
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center mx-4">
                      <i className="bi bi-journal-bookmark-fill"></i>
                    </div>
                    <div className="ps-1">

                      <button type="button" className="btn btn-success mx-2" onClick={() => navigate("/AnswerSheet")}><i className="bi bi-clipboard-check"></i> Answer Sheets</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <ToastContainer
          limit={1} />
      </main>

    </UserLayot>
  );
}
