import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../Componets/AdminLayout'; // Importing the AdminLayout component
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Importing Link and useNavigate from react-router-dom
import { ToastContainer, toast } from 'react-toastify'; // Importing ToastContainer and toast for displaying notifications
import 'react-toastify/dist/ReactToastify.css'; // Importing styles for react-toastify

export default function Result() {
  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
  const navigate = useNavigate(); // Using the useNavigate hook from react-router-dom
  // State variables
  const [records, setRecords] = useState([]); // State for storing fetched faculty records
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [loading, setLoading] = useState(false); // Loading state with initial value set to false
  const [totalRows, setTotalRows] = useState(0); // Total number of rows
  const [perPage, setPerPage] = useState(10); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const location = useLocation(); // Hook to access the current location
  const { id, subjectId } = location.state; // Destructure id and subjectId from the location state

  // Check if user role is ADMIN
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'ADMIN') {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    fetchRecords(currentPage, perPage); // Fetch records for the initial page
    // eslint-disable-next-line
  }, [currentPage, perPage]); // Trigger effect when currentPage or perPage changes

  // Function to fetch records with pagination
  const fetchRecords = async (page, perPage) => {
    setLoading(true); // Set loading state to true
    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    try {
      const response = await axios.get(`${apiUrl}/api/answersheet/result/${id}/${subjectId}?page=${page}&perPage=${perPage}`, {
        headers: {
          'Authorization': token // Set Authorization header with token
        }
      });
      const { results, count } = response.data; // Extract results and count from the API response
      setRecords(results); // Update records state with fetched data
      setTotalRows(count); // Update totalRows state with the total count from the API response
      setLoading(false); // Set loading state to false
    } catch (error) {
      console.error('Error fetching records:', error);
      setLoading(false); // Set loading state to false if there's an error
    }
  };

  // Columns configuration for DataTable
  const columns = [
    {
      name: "No",
      selector: (row, index) => index + 1,
      width: '50px',
      center: "true"
    },
    {
      name: "Barcode",
      selector: (row) => row.barcode,
      sortable: true,
    },
    {
      name: "Total Marks",
      selector: (row) => row.totalMarks,
      sortable: true,
      width: '150px',
    },
    {
      name: "Student Score",
      selector: (row) => row.obtainedMarks,
      sortable: true,
      width: '150px',
    },
    {
      name: "Result",
      selector: (row) => row.status,
      sortable: true,
      width: '100px',
      center: "true",
      cell: row => (
        row.status === 'pass' ? (
          <span className="badge bg-success">Pass</span>
        ) : row.status === 'fail' ? (
          <span className="badge bg-danger">Fail</span>
        ) : (
          <span className="badge bg-warning">Pending</span>
        )
      )
    }
  ];

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update currentPage state
  };

  // Function to handle number of items per page change
  const handlePerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage); // Update perPage state
    setCurrentPage(page); // Update currentPage state
  };

  return (
    <div>
      <main id="main" className="main">
        <AdminLayout>
          <div className="pagetitle ">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item  cursor-pointer" onClick={()=>navigate(-1)}>Exam Details</li>
                <li className="breadcrumb-item active">View Result</li>
              </ol>
            </nav>
          </div>
          <div className='container mt-2'>
            <div className="row">
              <div className="col-lg-15">
                <div className="card">
                  <div className="card-body mt-4 mb-1 mx-3">
                    <div className="datatable-top">
                      <div className="datatable-search">
                        <div className="datatable-search ">
                          <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={(records || []).filter(record =>
                          record.barcode.toLowerCase().includes(searchTerm.toLowerCase())
                        )}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerPageChange}
                        onChangePage={handlePageChange}
                        className="custom-table"
                      />
                      <ToastContainer limit={1} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      </main>
    </div>
  );
}
