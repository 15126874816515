import React, { useEffect, useState } from "react";
import AdminLayout from "../Componets/AdminLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


export default function AddFaculty() {
    // Retrieve the API URL from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const animatedComponents = makeAnimated(); // Enable animations for the select dropdown
    const [loaded, setLoaded] = useState(false); // save button  loading  efect deta  hold state 

    // Check if the user is an admin when the component mounts
    useEffect(() => {
        const role = localStorage.getItem("role");
        if (role !== 'ADMIN') {
            navigate("/"); // Redirect to home if the user is not an admin
        }
    }, [navigate]);

    // State to hold the list of subjects fetched from the API
    const [subjects, setSubjects] = useState([]);

    // Fetch the list of subjects from the API when the component mounts
    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/subject`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setSubjects(res.data.subjects);
            })
            .catch(err => console.log(err));
    }, [apiUrl]);

    // State to hold the form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        address: '',
        status: true,
        username: '',
        password: '',
        role: 'USER',
        remarks: '',
        subjectIds: [] // Array to hold selected subject IDs
    });

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        setLoaded(true);
        e.preventDefault(); // Prevent default form submission
        const token = localStorage.getItem('token');

        // Make a POST request to add new faculty
        axios.post(`${apiUrl}/api/faculty`, formData, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {

                toast.success('Faculty added successfully');// Show success toast notification

                setTimeout(() => navigate('/ListFaculty'), 3000);
                setFormData({ // Reset form data after successful submission
                    name: '',
                    email: '',
                    contactNo: '',
                    address: '',
                    status: true,
                    username: '',
                    password: '',
                    role: 'USER',
                    remarks: '',
                    subjectIds: []
                });
                setLoaded(false);
            })
            .catch(err => {

                toast.error(err.response.data.message);// Show error toast notification

                console.error("Error adding faculty:", err);
                setLoaded(false);
            });
    };

    // Function to handle form input changes
    const handleChange = (selectedOption, actionMeta) => {
        if (actionMeta.name === "subjectIds") {
            const ids = selectedOption ? selectedOption.map(option => option.value) : [];
            setFormData(prevState => ({
                ...prevState,
                [actionMeta.name]: ids
            }));
        } else {
            const { name, value } = actionMeta;
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    // Transform subjects to options for the select dropdown
    const subjectOptions = subjects.map(subject => ({
        value: subject.id,
        label: subject.name
    }));

    return (
        <>
            <AdminLayout>
                <main id="main" className="main">
                    <div className="pagetitle ">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Add New Faculty</li>
                            </ol>
                        </nav>
                    </div>

                    <section className="section col-lg-12">
                        <div className="row">
                            <div className="">
                                <div className="card">
                                    <div className="card-body mt-5 mb-1 mx-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className="container">
                                                <div className="row row-cols-2">
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Name"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="name">Name <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Email"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="email">Email <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="contactNo"
                                                                value={formData.contactNo}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Contact No"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="contactNo">Contact No <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="address"
                                                                value={formData.address}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Address"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="address">Address <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row row-cols-2">
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="username"
                                                                value={formData.username}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Username"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="username">Username <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                type={showPassword ? 'text' : 'password'}
                                                                name="password"
                                                                value={formData.password}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Password"
                                                                required
                                                            />
                                                            <label className="form-label" htmlFor="password">Password <span className="text-danger">*</span></label>
                                                            <button
                                                                className="btn btn mt-3"
                                                                type="button"
                                                                onClick={togglePasswordVisibility}
                                                                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                                                            >
                                                                {showPassword ? (
                                                                    <i className="bi bi-eye-slash"></i>
                                                                ) : (
                                                                    <i className="bi bi-eye"></i>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row row-cols-2">
                                                    <div className="col">
                                                        <div className="form-floating mb-2" >
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                components={animatedComponents}
                                                                placeholder="Select Subjects"
                                                                isMulti
                                                                name="subjectIds"
                                                                options={subjectOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={(selectedOptions) => handleChange(selectedOptions, { name: 'subjectIds' })}
                                                                value={subjectOptions.filter(option => formData.subjectIds.includes(option.value))}
                                                            />
                                                            {/* <label className="form-label" htmlFor="subjectIds">Subjects <span className="text-danger">*</span></label> */}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-2">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="remarks"
                                                                value={formData.remarks}
                                                                onChange={(e) => handleChange(e.target, e.target)}
                                                                placeholder="Remarks"

                                                            />
                                                            <label className="form-label" htmlFor="remarks">Remarks</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-sm-12 pt-4 text-end">
                                                    <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>

                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary  px-4 mx-2"
                                                        disabled={loaded}
                                                    >
                                                        {loaded ? (
                                                            <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Wait...</>
                                                        ) : (
                                                            <>
                                                                Save
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </AdminLayout>
        </>
    );
}
