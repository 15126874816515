import { useEffect } from "react";
import Layout from "../Componets/Layout";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function AddClients() {
  const apiUrl = process.env.REACT_APP_API_URL; // API URL from .env file
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [loaded, setLoaded] = useState(false); // save button  loading  efect deta  hold state 

  // Redirect if role is not SUPER_ADMIN
  useEffect(() => {
    const role = localStorage.getItem('role'); // Retrieve role from localStorage
    if (role !== 'SUPER_ADMIN') { // Redirect if role is not SUPER_ADMIN
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    website: '',
    address: '',
    city: '',
    pincode: '',
    contactPerson: '',
    contactNo: '',
    registrationDate: '',
    remarks: '',
  });

  // Function to handle form submission
  const handleSubmit = async (e) => {
    setLoaded(true);
    e.preventDefault(); // Prevent default form submission behavior
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    try {
      // Send POST request to the API to add a new client
      const response = await axios.post(`${apiUrl}/api/client`, formData, {
        headers: {
          'Authorization': `${token}`
        }
      });
      console.log(response.data); // Log the response data
      setLoaded(false);
      // Call notify function to show success message
      toast.success("Client added successfully");
      setTimeout(() => navigate('/ViewClient'), 3000);
      // Clear form fields after successful submission
      setFormData({
        name: '',
        email: '',
        website: '',
        address: '',
        city: '',
        pincode: '',
        contactPerson: '',
        contactNo: '',
        registrationDate: '',
        remarks: '',
      });
    } catch (error) {
      setLoaded(false);
      console.error(error); // Log error if submission fails
      toast.error(error.response.data.message);
    }
  };

  // Function to handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update formData state based on the input field's name
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'pincode' ? parseInt(value) || '' : value
    }));
  };

  // Function to show success message


  return (
    <>
      <Layout>
        <main id="main" className="main">
          <div className="pagetitle ">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item "><Link to="/Home">Dashboard</Link></li>
                <li className="breadcrumb-item active">Add New Clients</li>
              </ol>
            </nav>

          </div>
          {/* <!-- End Page Title --> */}
          <section className="section col-lg-12 ">
            <div className="row ">
              <div className="">
                <div className="card">
                  <div className="card-body mt-5 mx-4">
                    {/* <!-- General Form Elements --> */}
                    <form onSubmit={handleSubmit} >
                      {/* Detils of clinent */}
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
                            <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
                            <label className="form-label" htmlFor="txtGrNo">Address <span className="text-danger">*</span></label>
                          </div>
                        </div>
                        <div className="col-sm">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" required />
                            <label className="form-label" htmlFor="txtGrNo">City <span className="text-danger">*</span></label>
                          </div>
                        </div>
                        <div className="col-sm">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="number" name="pincode" value={formData.pincode} onChange={handleChange} placeholder="Pincode" required />
                            <label className="form-label" htmlFor="txtGrNo">Pin code <span className="text-danger">*</span></label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="contactPerson" value={formData.contactPerson} onChange={handleChange} placeholder="Contact Person" required />
                            <label className="form-label" htmlFor="txtGrNo">Contect Person <span className="text-danger">*</span></label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="contactNo" value={formData.contactNo} onChange={handleChange} placeholder="Contact Number" required />
                            <label className="form-label" htmlFor="txtGrNo">Contect Number <span className="text-danger">*</span></label>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                            <label className="form-label" htmlFor="txtGrNo">Email<span className="text-danger">*</span></label>
                          </div></div>
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="website" value={formData.website} onChange={handleChange} placeholder="Website" required />
                            <label className="form-label" htmlFor="txtGrNo">Website <span className="text-danger">*</span></label>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="date" name="registrationDate" value={formData.registrationDate} onChange={handleChange} placeholder="Registration Date" required data-date="" data-date-format="YYYY MM DD" />
                            <label className="form-label" htmlFor="txtGrNo">Registration Date <span className="text-danger">*</span></label>
                          </div></div>
                        <div className="col">
                          <div className="form-floating mb-2">
                            <input className="form-control" type="text" name="remarks" value={formData.remarks} onChange={handleChange} placeholder="Remarks" />
                            <label className="form-label" htmlFor="txtGrNo">Remarks</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-12 pt-4 text-end">
                          <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>
                          {/* <button type="submit" className="btn btn-primary  px-4 mx-2"  >
                            Save
                          </button> */}

                          <button
                            type="submit"
                            className="btn btn-primary  px-4 mx-2"
                            disabled={loaded}
                          >
                            {loaded ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Wait...</>
                            ) : (
                              <>
                                Save
                              </>
                            )}
                          </button>

                        </div>
                      </div>
                    </form>
                    <ToastContainer />
                    {/* <!-- End General Form Elements --> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
}
