import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../Componets/AdminLayout'; // Importing the AdminLayout component
import { Link, useNavigate } from 'react-router-dom'; // Importing Link and useNavigate from react-router-dom
import { ToastContainer, toast } from 'react-toastify'; // Importing ToastContainer and toast for displaying notifications
import 'react-toastify/dist/ReactToastify.css'; // Importing styles for react-toastify


export default function ListSubject() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate(); // Using the useNavigate hook from react-router-dom
    // State variables
    const [records, setRecords] = useState([]); // State for storing fetched faculty records
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [loading, setLoading] = useState(false); // Loading state with initial value set to false
    const [totalRows, setTotalRows] = useState(0); // Total number of rows
    const [perPage, setPerPage] = useState(10); // Number of items per page
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    // Check if user role is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
        // eslint-disable-next-line
    }, [navigate]);

    useEffect(() => {
        fetchRecords(currentPage, perPage); // Fetch records for the initial page
        // eslint-disable-next-line
    }, [currentPage, perPage]); // Trigger effect when currentPage or perPage changes

    // Function to fetch records with pagination
    const fetchRecords = async (page, perPage) => {
        setLoading(true); // Set loading state to true
        const token = localStorage.getItem('token'); // Retrieve token from localStorage

        try {
            const response = await axios.get(`${apiUrl}/api/subject?skip=${(page - 1) * perPage}&take=${perPage}`, {
                headers: {
                    'Authorization': token // Set Authorization header with token
                }
            });
            setRecords(response.data.subjects); // Update records state with fetched data
            setTotalRows(response.data.count); // Update totalRows state with total count
            setLoading(false); // Set loading state to false
        } catch (error) {
            console.error('Error fetching records:', error);
            setLoading(false); // Set loading state to false if there's an error
        }
    };

    // Function to handle deletion of a subject 
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this subject?')) {
            const token = localStorage.getItem('token'); // Retrieve token from localStorage

            try {
                await axios.delete(`${apiUrl}/api/subject/${id}`, {
                    headers: {
                        'Authorization': token // Set Authorization header with token
                    }
                });
                setRecords(records.filter(record => record.id !== id)); // Update records state after successful deletion
                toast.success('Subject deleted successfully'); // Display success toast
            } catch (error) {
                console.error('Error deleting faculty member:', error);
                toast.error(error.response.data.message); // Display error toast
            }
        }
    };

    // Columns configuration for DataTable
    const columns = [
        {
            name: "No",
            selector: (row, index) => index + 1,
            width: '50px ',
            center: 'true'
        },
        {
            name: "Name",
            selector: (row) => row.name, // Selector function for name column
            sortable: true,

        },
        {
            name: "Subject Code",
            selector: (row) => row.subjectCode,
            sortable: true,
            width: '150px',
            center: true,
            cell: (row) => (
                <div style={{ fontSize: '16px' }}>
                    {row.subjectCode}
                </div>
            )
        },
        {
            name: "Actions", // Column for actions (edit and delete)
            width: '100px',
            center: 'true',
            cell: (row) => (
                <div>
                    {/* Link to edit faculty member */}
                    <button onClick={() => navigate('/EditSubject', { state: { id: row.id } })} className="btn btn-primary btn-sm mx-2" ><i className="bi bi-pencil-square" ></i></button>
                    {/* Button to delete faculty member */}
                    <button className="btn btn-outline-danger btn-sm " onClick={() => handleDelete(row.id)}><i className="bi bi-trash3-fill"></i></button>
                </div>
            )
        },
    ];

    // Function to handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update currentPage state
    };

    // Function to handle number of items per page change
    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage); // Update perPage state
        setCurrentPage(page); // Update currentPage state
    };

    return (
        <div>
            <main id="main" className="main">
                <AdminLayout>
                    <div className="pagetitle ">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                                <li className="breadcrumb-item active">View Subject</li>
                            </ol>
                        </nav>

                    </div>
                    <div className='container mt-2'>
                        <div className="row">
                            <div className="col-lg-15">
                                <div className="card">
                                    <div className="card-body mt-4 mb-1 mx-3">

                                        <div className="datatable-top">
                                            <div className="datatable-search">
                                                <div className="datatable-search ">
                                                    <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                                </div>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary" onClick={() => navigate('/AddSubject')}><i className="bi bi-journal-bookmark-fill"></i> Add Subject</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={records.filter(record =>
                                                    record.name.toLowerCase().includes(searchTerm.toLowerCase())

                                                )}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerPageChange}
                                                onChangePage={handlePageChange}
                                                className="custom-table"
                                            />
                                            <ToastContainer limit={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </main>
        </div>
    );
}
