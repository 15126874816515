import React from 'react'
import { Link, } from 'react-router-dom'

export default function Error() {
  // this page >>  Navigate to this page when user passes wrong url 
  return (
    <main>
      <div id='Login-page'>

        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>404</h1>
          <h2>The page you are looking for doesn't exist.</h2>
          <Link className="btn mb-5" to="/">Back to home</Link>
          <div className="credits mt-5">
            Designed by
            <a href="https://printftech.com/"> PRiNTF Technologies</a>
          </div>
        </section>

      </div>
    </main>
  )
}
