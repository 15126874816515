
import { Routes, Route } from "react-router-dom";

// import all page here

import Login from "./Componets/Login";
import Error from "./Componets/Error ";

import Home from "./SuparAdmin/Home";
import ViewClient from "./SuparAdmin/ViewClient";
import ViewAdmins from "./SuparAdmin/ViewAdmins";
import AddClients from "./SuparAdmin/AddClients";
import AddAdmins from "./SuparAdmin/AddAdmins";
import Account from "./SuparAdmin/Account";
import EditClinet from "./SuparAdmin/EditClinet";
import EditAdmin from "./SuparAdmin/EditAdmin";

import AdminHome from "./Admin/AdminHome";
import ListFaculty from "./Admin/ListFaculty";
import AddFaculty from "./Admin/AddFaculty";
import EditFaculty from "./Admin/EditFaculty";
import AddExam from "./Admin/AddExam";
import ListExam from "./Admin/ListExam";
import AddSubject from "./Admin/AddSubject";
import ListSubject from "./Admin/ListSubject";
import EditSubject from "./Admin/EditSubject";
import EditExam from "./Admin/EditExam";
import ExamDetails from "./Admin/ExamDetails";
import ListSection from "./Admin/ListSection";
import AddSection from "./Admin/AddSection";
import EditSection from "./Admin/EditSection";
import AnswerSheetDetails from "./Admin/AnswerSheetDetails";
import AllocationDetails from "./Admin/AllocationDetails";
import SheetTransfer from "./Admin/SheetTransfer";
import Result from "./Admin/Result";

import UserHome from "./Faculty/UserHome";
import AnswerSheet from "./Faculty/AnswerSheet";
import ViewAnswerSheet from "./Faculty/ViewAnswerSheet";
import AllocateSheets from "./Faculty/AllocateSheets";


function App() {
  return (
    <>
      {/* routes set to all page here */}
      <Routes>
        {/* common pages */}
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Error />} />

        {/* Super Admin */}
        <Route path="/Home" element={<Home />} />
        <Route path="/ViewClient" element={<ViewClient />} />
        <Route path="/ViewAdmins" element={<ViewAdmins />} />
        <Route path="/AddClients" element={<AddClients />} />
        <Route path="/AddAdmins" element={<AddAdmins />} />
        <Route path="/Account" element={<Account />} />
        <Route path='/EditClinet' element={<EditClinet />} />
        <Route path='/EditAdmin' element={<EditAdmin />} />

        {/* Admin */}
        <Route path="/AdminHome" element={<AdminHome />} />
        <Route path="/ListFaculty" element={<ListFaculty />} />
        <Route path="/AddFaculty" element={<AddFaculty />} />
        <Route path="/EditFaculty" element={<EditFaculty />} />
        <Route path="/AddExam" element={<AddExam />} />
        <Route path="/ListExam" element={<ListExam />} />
        <Route path="/AddSubject" element={<AddSubject />} />
        <Route path="/ListSubject" element={<ListSubject />} />
        <Route path="/EditSubject" element={<EditSubject />} />
        <Route path="/EditExam" element={<EditExam />} />
        <Route path="/ExamDetails" element={<ExamDetails />} />
        <Route path="/AddSection" element={<AddSection />} />
        <Route path="/ListSection" element={<ListSection />} />
        <Route path="/EditSection" element={<EditSection />} />
        <Route path="/AnswerSheetDetails" element={<AnswerSheetDetails />} />
        <Route path="/AllocationDetails" element={<AllocationDetails />} />
        <Route path="/sheetTransfer" element={<SheetTransfer />} />
        <Route path="/Result" element={<Result />} />



        {/* Faculty */}

        <Route path="/UserHome" element={<UserHome />} />
        <Route path="/AnswerSheet" element={<AnswerSheet />} />
        <Route path="/ViewAnswerSheet" element={<ViewAnswerSheet />} />
        <Route path="/AllocateSheets" element={<AllocateSheets />} />

      </Routes>


    </>
  );
}

export default App;
