import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Componets/Layout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function EditClinet() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate();
    //  super admin only page access
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'SUPER_ADMIN') { // Adjusted comparison to match the role stored in localStorage
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);
    const location = useLocation();
    const { id } = location.state;

    const [value, setValue] = useState({
        name: '',
        email: '',
        address: '',
        city: '',
        pincode: '',
        contactPerson: '',
        contactNo: '',
        website: '',
        registrationDate: '',
        remarks: ''
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/client/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setValue({
                    name: res.data.name,
                    email: res.data.email,
                    address: res.data.address,
                    city: res.data.city,
                    pincode: parseInt(res.data.pincode), // Convert pincode to number
                    contactPerson: res.data.contactPerson,
                    contactNo: res.data.contactNo,
                    website: res.data.website,
                    registrationDate: res.data.registrationDate,
                    remarks: res.data.remarks
                });
            })
            .catch(err => console.error(err));
        // eslint-disable-next-line
    }, [id]);
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        axios.put(`${apiUrl}/api/client/${id}`, {
            ...value,
            pincode: parseInt(value.pincode) // Convert pincode to number
        }, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                console.log(res);
                toast.success("Client updated successfully");
                setTimeout(() => navigate(-1), 3000);
            })
            .catch(err => {
                console.error(err)
                toast.error(err.response.data.message);
            });
    }


    return (
        <Layout>
            <main id="main" className="main">
                <div className="pagetitle mb-4">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/Home">Dashboard</Link></li>
                            <li className="breadcrumb-item active"><Link to="/ViewClient">View Clients</Link></li>
                            <li className="breadcrumb-item active">Edit Clientss</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row ">
                        <div className="">
                            <div className="card">
                                <div className="card-body mt-5 mb-1 mx-3">
                                    {/* <!-- General Form Elements --> */}
                                    <form onSubmit={handleSubmit} >
                                        {/* Detils of clinent */}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.name} onChange={(e) => setValue({ ...value, name: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.address} onChange={(e) => setValue({ ...value, address: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Address <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.city} onChange={(e) => setValue({ ...value, city: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">City <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="number" placeholder="Name" value={value.pincode} onChange={(e) => setValue({ ...value, pincode: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Pin code <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.contactPerson} onChange={(e) => setValue({ ...value, contactPerson: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Contect Person <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.contactNo} onChange={(e) => setValue({ ...value, contactNo: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Contect Number <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.email} onChange={(e) => setValue({ ...value, email: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Email<span className="text-danger">*</span></label>
                                                </div></div>
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.website} onChange={(e) => setValue({ ...value, website: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Website <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="date" data-date="" data-date-format="YYYY MM DD" placeholder="Name" value={value.registrationDate} onChange={(e) => setValue({ ...value, registrationDate: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Registration Date <span className="text-danger">*</span></label>
                                                </div></div>
                                            <div className="col">
                                                <div className="form-floating mb-2">
                                                    <input className="form-control" type="text" placeholder="Name" value={value.remarks} onChange={(e) => setValue({ ...value, remarks: e.target.value })} />
                                                    <label className="form-label" htmlFor="txtGrNo">Remarks</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-sm-12 pt-4 text-end">
                                                <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>
                                                <button type="submit" className="btn btn-primary  px-4 mx-2" >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <ToastContainer limit={1} />
                                    {/* <!-- End General Form Elements --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </Layout>
    );
}
