import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from "../Componets/UserLayout";
import DataTable from 'react-data-table-component';
import axios from "axios";
import dayjs from 'dayjs';  // Import dayjs for date formatting



export default function AnswerSheet() {
  const [data, setData] = useState([]);  // State to hold the API response data
  const [searchText, setSearchText] = useState("");  // State to hold the search text
  const apiUrl = process.env.REACT_APP_API_URL;  // Retrieve the API URL from environment variables
  const navigate = useNavigate();

  // Checking if user is logged in, if not then redirect to login page
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'USER') {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  // Fetch data from API
  useEffect(() => {
    const token = localStorage.getItem('token');
    async function FechDataWithAxios() {
      try {
        const response = await axios.get(`${apiUrl}/api/answersheet/faculty/detail`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        // console.log(response.data); // Log the response data to check the structure
        setData(response.data); // Update the state with the fetched data
        notify();
      } catch (error) {
        console.error('Error fetching the data', error); // Log any errors
      }
    }
    FechDataWithAxios(); // Call the function when the component mounts
  }, [apiUrl]);
  const notify = () => toast.success("This time allotment of answer sheet has been sent for you.");
  // Define a function to format the date
  const formatDate = (dateString) => {
    return dayjs(dateString).format('DD/MM/YYYY');
  };

  // Filter data based on search text
  const filteredData = data.filter(item =>
    item.examName.toLowerCase().includes(searchText.toLowerCase()) ||
    item.subjectName.toLowerCase().includes(searchText.toLowerCase())
  );

  // Define columns
  const columns = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: '50px',
      center: "true",
    },
    {
      name: 'Exam Name',
      selector: row => row.examName,
      sortable: "true",
    },
    {
      name: 'Subject',
      selector: row => row.subjectName,
    },
    {
      name: 'AnswerSheet Count',
      selector: row => row.answerSheetCount,
      width: '160px',
      center: "true",
    },
    {
      name: 'Assigning Date',
      selector: row => formatDate(row.assignDate),  // Use the formatDate function
      width: '150px',
    },
    {
      name: 'Details',
      width: '90px',
      center: "true",
      cell: (row) => (
        <div>
          <button
            className="btn btn-secondary btn-sm "
            onClick={() => navigate('/AllocateSheets', { state: { id: row.examId, subjectId: row.subjectId } })}
          >
            <i className="bi bi-list-columns"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <UserLayout>
      <main id="main" className="main">
        <div className="pagetitle ">

          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item "><Link to="/UserHome">Dashboard</Link></li>
              <li className="breadcrumb-item active">Exam Details</li>
            </ol>
          </nav>
        </div>
        {/* Page */}
        <div className="card">
          <div className="card-body mt-5 mb-1 mx-3">
            <div className="datatable-top">
              <div className="datatable-search">
                <input
                  className="form-control"
                  type="search"
                  name="search"
                  placeholder="Search"
                  title="Search within table"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)} // Update search text on change
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredData} // Use filtered data for the table
              pagination
              className="custom-table"
            />
          </div>
        </div>
        <ToastContainer limit={1} />
      </main>
    </UserLayout>
  );
}
