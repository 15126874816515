import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import AdminLayout from '../Componets/AdminLayout';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ListFaculty() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate();
    const [loadingMap, setLoadingMap] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [records, setRecords] = useState([]);
    // Check if user role is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        fetchRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage]);


    // Function to fetch records with pagination    
    const fetchRecords = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${apiUrl}/api/faculty?page=${currentPage}&perPage=${perPage}&search=${searchTerm}`, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            setRecords(response.data.facultys);
            setTotalRows(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    // Function to handle status toggle
    const toggleStatus = (id) => {
        setLoadingMap(prevLoadingMap => ({
            ...prevLoadingMap,
            [id]: true
        }));
    
        const token = localStorage.getItem('token');
        const currentStatus = getStatus(id); // Get the current status
    
        axios.patch(`${apiUrl}/api/faculty/${id}`, {
            status: !currentStatus // Toggle status
        }, {
            headers: {
                'Authorization': `${token}`
            }
        })
        .then(res => {
            setRecords(records.map(record => {
                if (record.id === id) {
                    return { ...record, status: res.data.status };
                }
                return record;
            }));
        })
        .catch(err => console.error('Error toggling status:', err))
        .finally(() => {
            setTimeout(() => {
                setLoadingMap(prevLoadingMap => ({
                    ...prevLoadingMap,
                    [id]: false
                }));
    
                // Show success message after loading completes
                if (!currentStatus) {
                    toast.success("Faculty member activated successfully");
                } else {
                    toast.error("Faculty member deactivated successfully");
                }
            }, 2000);
        });
    };
    



    const getStatus = (id) => {
        const record = records.find(record => record.id === id);
        return record ? record.status : false;
    };
    // Function to handle deleting a faculty member
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this faculty member?')) {
            const token = localStorage.getItem('token');
            axios.delete(`${apiUrl}/api/faculty/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
                .then(res => {
                    setRecords(records.filter(record => record.id !== id));
                    toast.success("Faculty member deleted successfully");
                })
                .catch(err =>
                    toast.error(err.response.data.message)
                );
        }
    };

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            width: '50px ',
            center: "true"
        },

        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: '130px'
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            width: '130px'

        },
        {
            name: "Contact No",
            selector: (row) => row.contactNo,
            sortable: true,
            width: '120px'
        },
        {
            name: "User Name",
            selector: (row) => row.username,
            sortable: true,
            width: '130px'
        },
        {
            name: "Subjects",
            selector: row => formatSubjects(row.facultySubjects),
            sortable: true,
        },
        {
            name: "Actons",
            width: '140px',
            center: 'true',
            cell: (row) => (
                <div>
                    {/* Button to toggle status */}
                    <button className={`btn btn-${getStatus(row.id) ? 'success' : 'danger'} btn-sm ${getStatus(row.id) ? '' : ''}`} onClick={() => toggleStatus(row.id)} disabled={loadingMap[row.id]}>
                        {loadingMap[row.id] ? (
                            <i className="spinner-border spinner-border-sm" role="status"></i>
                        ) : (
                            getStatus(row.id) ? <i className="bi bi-check-circle"></i> : <i className="bi bi-x-circle"></i>
                        )}
                    </button>
                    {/* Button to edit a faculty */}
                    <button type="button" className="btn btn-primary btn-sm mx-2" onClick={() => navigate('/EditFaculty', { state: { id: row.id } })} ><i className="bi bi-pencil-square" ></i></button>

                    {/*  button to delete a faculty */}
                    <button type="button" className="btn btn-outline-danger btn-sm " onClick={() => handleDelete(row.id)} disabled={loadingMap[row.id]}><i className="bi bi-trash3-fill"></i></button>
                </div>
            )
        },

    ];
    // faculty subject formatting
    const formatSubjects = (subjects) => {
        if (Array.isArray(subjects)) {
            return subjects.map((subject, index) => (
                <span key={index} className="badge bg-secondary me-1 p-1">
                    <i className="bi bi-journal-album"></i>&nbsp;
                    {subject}
                    {index < subjects.length - 1 && ', '}
                </span>

            ));
        }
        return <span className="badge bg-secondary">{subjects}</span>;
    };

    return (
        <div>
            <main id="main" className="main">
                <AdminLayout>
                    <div className="pagetitle ">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                                <li className="breadcrumb-item active">View faculty</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='container  mt-4'>
                        <div className="row ">
                            <div className="col-lg-16 ">
                                <div className="card  ">
                                    <div className="card-body mt-4 mb-1 mx-3">
                                        {/* <h5 className="card-title"></h5> */}
                                        <div className="datatable-top">
                                            <div className="datatable-search ">
                                                <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary" onClick={() => navigate('/AddFaculty')}><i className="bi bi-person-fill-add"></i> Add Faculty</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={records.filter(record =>
                                                    record.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    record.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    record.contactNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    record.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    record.remarks.toLowerCase().includes(searchTerm.toLowerCase())
                                                )}

                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerPageChange}
                                                onChangePage={handlePageChange}

                                                className="custom-table"
                                            />
                                            <ToastContainer />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </AdminLayout>
            </main>
        </div>
    );
}
