import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../Componets/AdminLayout';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function ListExam() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10); // Initial rows per page

    // Check if user role is ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {

        fetchExams(1, perPage); // Fetch data for the initial page
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to fetch exams with pagination
    const fetchExams = async (page, perPage) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/api/exam?skip=${(page - 1) * perPage}&take=${perPage}`, {
            headers: {
                'Authorization': `${token}`
            }
        });
        setRecords(response.data.exams);
        setTotalRows(response.data.count);
        setLoading(false);
    };

    // Function to handle pagination change
    const handlePageChange = page => {
        fetchExams(page, perPage);
    };
    // Function to handle rows per page change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchExams(1, newPerPage);
    };
    // Function to handle deleting an exam
    const handleDelete = (id) => {
        alert('Are you sure you want to delete this Exam?');
        const token = localStorage.getItem('token');
        axios.delete(`${apiUrl}/api/exam/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setRecords(records.filter(record => record.id !== id));

                toast.success("Exam deleted successfully");
            })
            .catch(err => {

                toast.error(err.response.data.message);
            });
    };

    // DataTable columns configuration
    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            center: "true",

            width: '50px',
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Details',
            width: '100px',
            center: "true",
            cell: (row) => (
                <div >

                    <button type="button" className="btn btn-secondary btn-sm " onClick={() => navigate('/ExamDetails', { state: { id: row.id } })}><i className="bi bi-list-columns"></i></button>
                </div>
            )
        },
        {
            name: 'Actions',
            width: '100px',
            center: "true",
            cell: (row) => (
                <div>

                    {/* edit button */}
                    <button type="button" className="btn btn-primary btn-sm mx-2" onClick={() => navigate('/EditExam', { state: { id: row.id } })} ><i className="bi bi-pencil-square" ></i></button>


                    {/* delete button   */}
                    <button type="button" className="btn btn-outline-danger btn-sm " onClick={() => handleDelete(row.id)}><i className="bi bi-trash3-fill"></i></button>

                </div>
            )
        }
    ];

    return (
        <>

            <div>
                <AdminLayout>
                    <main id="main" className="main">
                        <div className="pagetitle ">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link to="/AdminHome">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">View Exam</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='container mt-2'>
                            <div className="card">
                                <div className="card-body mt-4 mb-1 mx-3">
                                    <div className="datatable-top">
                                        <div className="datatable-search ">
                                            <div className="datatable-search ">
                                                <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                            </div>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary " onClick={() => navigate('/AddExam')} ><i className="bi bi-journal-text"></i> Add Exam</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={records.filter(record =>
                                                record.name.toLowerCase().includes(searchTerm.toLowerCase())
                                            )}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            className="custom-table"
                                        />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </AdminLayout>


            </div>
        </>
    );
}
