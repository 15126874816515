import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../Componets/AdminLayout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditExam() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state;
    const [value, setValue] = useState({
        name: '',
        isLocked: false,
        subjects: []
    });
    const [subjectList, setSubjectList] = useState([]);

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'ADMIN') {
            navigate("/");
        }

        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/exam/${id}`, {
            headers: { 'Authorization': `${token}` }
        })
            .then(res => {
                setValue({
                    name: res.data.name,
                    isLocked: res.data.isLocked || false,
                    subjects: res.data.subjects.map(subject => ({
                        ...subject,
                        isLocked: subject.isLocked || false
                    })) || []
                });
            })
            .catch(err => console.error(err));

        axios.get(`${apiUrl}/api/subject`, {
            headers: { 'Authorization': `${token}` }
        })
            .then(res => {
                setSubjectList(res.data.subjects || []);
            })
            .catch(err => console.error(err));
    }, [apiUrl, id, navigate]);

    const handleAddSubject = () => {
        setValue(prevState => ({
            ...prevState,
            subjects: [
                ...prevState.subjects,
                { subjectId: '', totalMarks: '', passingMarks: '', isLocked: false }
            ]
        }));
    };

    const prepareDataForSubmission = (data) => {
        return {
            ...data,
            subjects: data.subjects.map(subject => ({
                subjectId: parseInt(subject.subjectId),
                passingMarks: parseInt(subject.passingMarks),
                totalMarks: parseInt(subject.totalMarks),
               
            }))
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const updatedValue = prepareDataForSubmission({
            ...value,
            isLocked: value.isLocked
        });

        axios.put(`${apiUrl}/api/exam/${id}`, updatedValue, {
            headers: { 'Authorization': `${token}` }
        })
            .then(res => {
                console.log(res);
                toast.success("Exam information updated successfully");
                setTimeout(() => navigate(-1), 3000);
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });
    };

    const handleDeleteSubject = (index) => {
        const updatedSubjects = [...value.subjects];
        updatedSubjects.splice(index, 1);
        setValue({ ...value, subjects: updatedSubjects });
    };

    // Function to get available subjects for a specific index
    const getAvailableSubjects = (index) => {
        const selectedSubjectIds = value.subjects.map(subject => subject.subjectId);
        return subjectList.filter(subject => !selectedSubjectIds.includes(subject.id) || selectedSubjectIds[index] === subject.id);
    };

    return (
        <AdminLayout>
            <main id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/AdminHome">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/ListExam">View Exam</Link></li>
                            <li className="breadcrumb-item active">Edit Exam</li>
                        </ol>
                    </nav>
                </div>
                <section className="section col-lg-12">
                    <div className="row">
                        <div className="">
                            <div className="card">
                                <div className="card-body pt-5">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-floating mb-2">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Name"
                                                        value={value.name}
                                                        onChange={(e) => setValue({ ...value, name: e.target.value })}
                                                    />
                                                    <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Subject Details */}
                                        {value.subjects && value.subjects.map((subject, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-4">
                                                    <div className="form-floating mb-2">
                                                        <select
                                                            className="form-select"
                                                            value={subject.subjectId}
                                                            onChange={(e) => {
                                                                const updatedSubjects = [...value.subjects];
                                                                updatedSubjects[index].subjectId = e.target.value;
                                                                setValue({ ...value, subjects: updatedSubjects });
                                                            }}
                                                        >
                                                            <option value="">Select Subject</option>
                                                            {getAvailableSubjects(index).map((subjectOption, idx) => (
                                                                <option key={idx} value={subjectOption.id}>{subjectOption.name}</option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label" htmlFor="txtGrNo">Subject Id <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-floating mb-2">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="Total Marks"
                                                            value={subject.totalMarks}
                                                            onChange={(e) => {
                                                                const updatedSubjects = [...value.subjects];
                                                                updatedSubjects[index].totalMarks = e.target.value;
                                                                setValue({ ...value, subjects: updatedSubjects });
                                                            }}
                                                        />
                                                        <label className="form-label" htmlFor="txtGrNo">Total Marks <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-floating mb-2">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="Passing Marks"
                                                            value={subject.passingMarks}
                                                            onChange={(e) => {
                                                                const updatedSubjects = [...value.subjects];
                                                                updatedSubjects[index].passingMarks = e.target.value;
                                                                setValue({ ...value, subjects: updatedSubjects });
                                                            }}
                                                        />
                                                        <label className="form-label" htmlFor="txtGrNo">Passing Marks <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm mt-2 mx-2 ms-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleDeleteSubject(index)}
                                                    >
                                                        <i className="bi bi-trash-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='text-end button-edit-exam'>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleAddSubject}
                                            >
                                                <i className="bi bi-journal-plus"></i>
                                            </button>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12 pt-4 text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary px-3"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    <i className="bi bi-arrow-bar-left"></i>Back
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary px-4 mx-2"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <ToastContainer  />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </AdminLayout>
    );
}
