import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Layout from '../Componets/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ViewClient() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // cheking role and redirecting to login page
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'SUPER_ADMIN') { // Adjusted comparison to match the role stored in localStorage
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    const fetchClients = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/client`, {
                params: {
                    skip: (currentPage - 1) * perPage,
                    take: perPage,
                    searchTerm: searchTerm
                },
                headers: {
                    'Authorization': `${token}`
                }
            });
            setRecords(response.data.clients);
            setTotalRows(response.data.totalCount);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching clients:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClients();
        // eslint-disable-next-line
    }, [currentPage, perPage, searchTerm]);

    const handleDelete = (id) => {
        alert('Are you sure you want to delete this client?');
        const token = localStorage.getItem('token');
        axios.delete(`${apiUrl}/api/client/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                setRecords(records.filter(record => record.id !== id));

                toast.success("Client deleted successfully");
            })
            .catch(err => {
                console.error('Error deleting client:', err);
                toast.error(err.response.data.message);
            });
    };

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width: '50px ',
            center: "true",
        },

        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: '140px '
        },
        {
            name: "City",
            selector: (row) => row.city,
            sortable: true,
        },
        {
            name: "Contact Person",
            selector: (row) => row.contactPerson,
            sortable: true,
            width: '130px '

        },
        {
            name: "Contact No",
            selector: (row) => row.contactNo,
            sortable: true,
            width: '130px '
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,

        },
        {
            name: "Remark",
            selector: (row) => row.remarks,
            sortable: true,

        },
        {
            name: 'Actions',
            width: '135px ',
            center: "true",
            cell: (row) => (
                <div  >
                    <button type="button" className="btn btn-primary btn-sm mx-2" onClick={() => navigate('/EditClinet', { state: { id: row.id } })}><i className="bi bi-pencil-square" ></i></button>
                    <button type="button" className="btn btn-outline-danger btn-sm " onClick={() => handleDelete(row.id)}><i className="bi bi-trash3-fill"></i></button>
                </div>
            )
        }
    ];


    // Function to handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };
    // Function to handle number of items per page change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    return (
        <main id="main" className="main">
            <Layout>
                <div className="pagetitle ">
                    {/* <h1>View Clients</h1> */}
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><Link to="/Home">Dashboard</Link></li>
                            <li className="breadcrumb-item active">View Clients</li>
                        </ol>
                    </nav>
                </div>

                <div className="card">
                    <div className="card-body mt-4 mb-1 mx-3">
                        <div className="datatable-top">
                            <div className="datatable-search ">
                                <div className="datatable-search ">
                                    <input className="form-control" type="search" name="totalMarks" placeholder="Search" title="Search within table" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary " onClick={() => navigate('/AddClients')} ><i className="bi bi-person-fill-add"></i> Add Clients</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={records.filter(record =>
                                    (record.name && record.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                    (record.city && record.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                    (record.contactPerson && record.contactPerson.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                    (record.contactNo && record.contactNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                    (record.email && record.email.toLowerCase().includes(searchTerm.toLowerCase()))
                                )}
                                // stretch
                                pagination

                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}

                                className="custom-table"
                            ></DataTable>
                            <ToastContainer />
                        </div>


                    </div>
                </div>
            </Layout>
        </main>
    )
}
