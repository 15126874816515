import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../App.css";

export default function Sidebar({ R1, R2, R3, R4, R5, I1, I2, I3, I4, I5, P1, P2, P3, P4, P5, f3, f5 }) {
  const location = useLocation();

  // Function to check if the link is active
  const isActive = (route) => location.pathname === route;

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link to={R1} className={`nav-link ${isActive(R1) ? "active" : "collapsed"}`}>
              <i className={I1}></i>
              <span>{P1}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={R2} className={`nav-link ${isActive(R2) ? "active" : "collapsed"}`}>
              <i className={I2}></i>
              <span>{P2}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={R3} onClick={f3} className={`nav-link ${isActive(R3) ? "active" : "collapsed"}`}>
              <i className={I3}></i>
              <span>{P3}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={R4} className={`nav-link ${isActive(R4) ? "active" : "collapsed"}`}>
              <i className={I4}></i>
              <span>{P4}</span>
            </Link>
          </li>
          <li className="nav-item position-fixed bottom-0">
            <Link to={R5} onClick={f5} className={`nav-link ${isActive(R5) ? "active" : "collapsed"}`}>
              <i className={I5}></i>
              <span>{P5}</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}
