
import AdminLayout from '../Componets/AdminLayout'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';




export default function AdminHome() {
  const navigate = useNavigate(); // Use navigate hook from react-router-dom

  // cheking if the user is logged in or not then redirecting to home page
  useEffect(() => {
    const role = localStorage.getItem("role");

    if (role !== 'ADMIN') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  //  alert function to show alert when login successfully
  useEffect(() => {
    if (!localStorage.getItem('alertShown')) {
      // Show the alert
      notify();

      // Set a flag in local storage to indicate that the alert has been shown
      localStorage.setItem('alertShown', 'true');
    }
  }, []);

  const notify = () => toast.success("Login successfully");
  return (

    <AdminLayout>
      <main id="main" className="main">
        <div className="pagetitle  mb-4">
          <h1>Dashboard</h1>
        </div>
        {/* ------------------------------------------  Page Title ---------------------------- */}

        <section className="section dashboard">
          <div className="row">
            {/* ------------------------------ Manage Subject -------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card customers-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/AddSubject")}
                  >
                    Manage Subject
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="  card-icon rounded-circle d-flex align-items-center justify-content-center mx-4">
                      <i className="bi bi-journal-bookmark-fill"></i>
                    </div>
                    <div className="ps-1">
                      <button type="button" className="btn btn-primary mx-2" onClick={() => navigate("/AddSubject")}><i className="bi bi-plus-circle"></i> Add</button>
                      <button type="button" className="btn btn-success" onClick={() => navigate("/ListSubject")}><i className="bi bi-eye"></i> View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----------------------------- Manage Faculty --------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/ListFaculty")}
                  >
                    Manage Faculty
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center mx-4">
                      <i className="bi bi-person-fill-gear"></i>
                    </div>
                    <div className="ps-1">
                      <button type="button" className="btn btn-primary mx-2" onClick={() => navigate("/AddFaculty")}><i className="bi bi-plus-circle"></i> Add</button>
                      <button type="button" className="btn btn-success" onClick={() => navigate("/ListFaculty")}><i className="bi bi-eye"></i> View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------------------ Manage Exams -------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card revenue-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/ListExam")}
                  >
                    Manage Exams
                  </h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center mx-4" >
                      <i className="bi bi-person-fill-add "></i>
                    </div>
                    <div className="ps-1">
                      <button type="button" className="btn btn-primary mx-2" onClick={() => navigate("/AddExam")}><i className="bi bi-plus-circle"></i> Add</button>
                      <button type="button" className="btn btn-success" onClick={() => navigate("/ListExam")}><i className="bi bi-eye"></i> View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          limit={1} />
      </main>
    </AdminLayout>

  )
}
