import React from 'react'
import { Link } from 'react-router-dom'


export default function HeaderAnswersheet(props) {
  const user = localStorage.getItem('name')
  const role = localStorage.getItem('role')
  return (

    <header id="header" className="header fixed-top d-flex align-items-center" style={{ height: '47px' }}>

      <div className="d-flex align-items-center justify-content-between">
        <Link to="/UserHome" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt="" />
          <span className="d-none d-lg-block">{props.Heading}</span>
        </Link>

      </div>
      {/* <!-- End Logo --> */}


      {/* <!-- End Search Bar --> */}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">

          {/* <!-- End Messages Nav --> */}

          <li className="nav-item dropdown pe-3">

            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#!" data-bs-toggle="dropdown">
              <img
                src="assets/img/profile-img.png"
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">{user}</span>
            </a>
            {/* <!-- End Profile Iamge Icon --> */}

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{user}</h6>
                <span>{role}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  to="/"
                  className="dropdown-item d-flex align-items-center"

                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span onClick={() => localStorage.clear()}>Sign Out</span>
                </Link>
              </li>

            </ul>
            {/* <!-- End Profile Dropdown Items --> */}
          </li>
          {/* <!-- End Profile Nav --> */}

        </ul>
      </nav>
      {/* <!-- End Icons Navigation --> */}

    </header>
  )
}
