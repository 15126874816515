import React, { useState, useRef, useEffect } from 'react';
import Konva from 'konva';
import HeaderAnswersheet from '../Componets/HeaderAnswersheet';
import { MdCleaningServices } from "react-icons/md";
import { TbSlashes } from "react-icons/tb";
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Tooltip } from 'react-tooltip'


export default function ViewAnswerSheet() {
  const navigate = useNavigate();
  const [stage, setStage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const location = useLocation();
  const { id, subjectId, barcode, barcodeId } = location.state; // Extract the exam ID subject ID  and barcod number from the location state
  const stageRef = useRef(null);// stage for image konva
  const selectedElementRef = useRef(null); // selected element ref konva  
  const layerRef = useRef(null);// layer for image  konva
  const transformerRef = useRef(null); // transformer for select element  konva
  const apiUrl = process.env.REACT_APP_API_URL;  // Retrieve the API URL from environment variables
  const [showModal, setShowModal] = useState(false); //handleButtonClick Modal state
  const [pagecount, setPagecount] = useState(0); //total page are here to set
  const [pageValues, setPageValues] = useState([]); // image pagination
  const [currentIndex, setCurrentIndex] = useState(0); // image pagination
  const pathname = `${pageValues[currentIndex]}` // image pagination
  const [loading, setLoading] = useState(true); // image loading  efect deta  hold state
  const [loaded, setLoaded] = useState(false); // save button  loading  efect deta  hold state 
  const [selectedOptionpoint, setSelectedOptionpoint] = useState(''); // this is select marks 
  const [image, setImage] = useState('');// that is my img state
  const [data, setQsnNo] = useState([]); // right said fast table qsn no hold state
  const [qsnText, setQsnText] = useState([]); // right said fast table qsn text hold state
  const [enable, setEnable] = useState(true); // left said buttons enable disable hold state
  const [section, setSection] = useState([]); // right said  table qsn text with pagination hold state
  const [sectionName, setSectionName] = useState([]); // right said  table qsn text with pagination hold state
  const [totalMarks, setTotalMarks] = useState() // right said  table qsn text with pagination hold state
  const [selectEnable, setSelectEnable] = useState()
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0); // State for selected value
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0); // State for current section index
  const [qsenId, setQsenId] = useState(null) // State for current section index 
  const [fullMarks, setFullMarks] = useState() // State for full marks
  const [pointMarks, setPointMarks] = useState() // State for point marks
  const [pdfBlob, setPdfBlob] = useState(null); // State for  hold qsnpepr pdf
  const [answerKeyBlob, setAnswerKeyBlob] = useState(null); // State for  hold answer key pdf
  const [activeButtons, setActiveButtons] = useState({}); // State for  hold  blink button state
  const score = (fullMarks + pointMarks)
  const [clearTrigger, setClearTrigger] = useState(false);
  const [grandTotal, setGrandTotal] = useState(); // State for grand total
  const fetchTimeoutId = useRef(null);

  // Function to generate the array for qsen total mark 0 to total mark
  useEffect(() => {
    // Function to generate the array
    const generateArray = (length) => {
      const arr = [];
      for (let i = 0; i <= length; i++) {
        arr.push(i);
      }
      return arr;
    };
    // Generate the array when component mounts or totalMarks changes
    const resultArray = generateArray(totalMarks);
    setOptions(resultArray);
    // Reset selected value to 0 when totalMarks changes
    setSelectedValue(0);
  }, [totalMarks]); // Re-run effect if totalMarks changes

  //-------------secton with pagination (NextValue) and (PreviousValue) for section ----------------
  const [jsonData, setJsonData] = useState({
    sections: [], // Initialize with an empty array
  });

  //--------- Get the current section -----------
  const currentSection = jsonData.sections[currentSectionIndex];

  // Now, you can directly use the properties of currentSection without converting it to a string
  useEffect(() => {
    if (currentSection) {
      const om = currentSection; // Accessing a specific property
      setSection(om.questions);
      setSectionName(om.name);
    }
  }, [currentSection]); // This useEffect will trigger whenever currentSection changes

  //----------- Api Call for Image path loding and secod api call was actual image loding ----------
  useEffect(() => {
    if (fetchTimeoutId.current) {
      clearTimeout(fetchTimeoutId.current);
    }
    fetchTimeoutId.current = setTimeout(() => {
      jsonDatalode();
    }, 300);
    return () => {
      if (fetchTimeoutId.current) {
        clearTimeout(fetchTimeoutId.current);
      }
    };
    // eslint-disable-next-line
  }, [apiUrl, id, subjectId, barcode, pathname]);


  //----------- Api Call for Image path loding and secod api call was actual image loding  funton ----------
  const jsonDatalode = () => {
    const token = localStorage.getItem('token');
    async function fetchFirstAPI() {
      try {
        const response = await axios.get(`${apiUrl}/api/answersheet/image/detail/${id}/${subjectId}/${barcode}`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        setPagecount(response.data.totalPages);
        setTimeout(() => {
          addIconToStage();
        }, 5000);
        const pageValues = response.data.pages.map(page => Object.entries(page)
          .filter(([key, value]) => key.startsWith("page-"))
          .map(([key, value]) => value)
        ).flat();
        // thete code for image pagination and loading json on stage
        const page = response.data.pages[currentIndex];
        if (page) {

          page.imageJson.map((image, index) => {
            if (image?.x) {
              setTimeout(() => {
                addIconToStage(
                  `/assets/img/${image.path}`,
                  image.x,
                  image.y,
                  image.width,
                  image.height,
                  image.zIndex
                );
              }, 300);
            }
          });
        }

        setPageValues(pageValues);
      } catch (error) {
        console.error('Error fetching the first API data', error); // Log any errors
      }
    }
    async function fetchSecondAPI() {
      try {
        setLoading(true); // Set loading to true before making the API call
        const response = await axios.get(`${apiUrl}/api/answersheet/image/${id}/${subjectId}/${barcode}/${pathname}`, {
          headers: {
            'Authorization': token
          }
        });
        setImage(response.data.base64Image); // Set the image state with the base64Img
      } catch (error) {
        // console.error('Error fetching the second API data', error); // Log any errors
      } finally {
        // Use setTimeout to set loading to false after 4 seconds
        setTimeout(() => {
          setLoading(false);
        }, 4000);
      }
    }
    // Start the loading indicator for the second API call
    setLoading(true);
    // Fetch the first API data immediately
    fetchFirstAPI();
    // Set a timeout to call the second API after 1 seconds
    const fetchSecondAPITimeout = setTimeout(() => {
      fetchSecondAPI();
    }, 0); // Adjust the delay as needed
    // Clean up function
    return () => {
      clearTimeout(fetchSecondAPITimeout); // Clear the timeout if the component unmounts or the effect runs again
    };
  }
  // ---------------------------------------------------- konva start here ----------------------------------------

  //----------- function that are work for  set image  position and stage --------------
  const initializeStage = () => {
    const stageContainer = stageRef.current;
    stageContainer.style.height = '85.5vh'; // Set your desired height
    stageContainer.style.width = '65.8vw';
    stageContainer.style.overflowY = 'auto';

    // Konva stage setup
    const stage = new Konva.Stage({
      container: stageContainer,
      width: 839,
      height: 1000,
      draggable: false, // Initially not draggable
    });

    const layer = new Konva.Layer();
    stage.add(layer);
    layerRef.current = layer;

    const imageObj = new window.Image();

    imageObj.onload = () => {
      const img = new Konva.Image({
        x: 0,
        y: 10,
        width: 839,
        height: 1000,
        image: imageObj,
        name: 'img',
        listening: false,
        zIndex: -1,
      });

      layer.add(img);
      layer.draw();

      const tr = new Konva.Transformer({
        rotateEnabled: false,
      });

      layer.add(tr);
      transformerRef.current = tr;

      stage.on('click', (e) => {
        if (e.target === stage) {
          deselectElement();
        }
      });

      setStage(stage); // Store the stage instance in state
      setLoading(false); // Set loading to false after image has loaded
    };

    imageObj.onerror = () => {
      setLoading(false); // Set loading to false in case of error
    };

    imageObj.src = `data:image/png;base64,${image}`; // Set the source of the image to the base64 string in the state

    // Handle zooming only when the Control key is pressed
    stage.on('wheel', (e) => {
      if (!e.evt.ctrlKey) {
        return; // Exit if the Control key is not pressed
      }

      e.evt.preventDefault(); // Prevent default scrolling behavior

      const scaleBy = 1.05; // Adjust this value for faster/slower zooming
      const oldScale = stage.scaleX(); // Assuming uniform scaling (x and y are the same)
      const pointer = stage.getPointerPosition(); // Get current mouse position

      // Calculate the new scale based on the scroll direction
      const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

      // Calculate the position to keep the zoom centered on the mouse pointer
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      // Apply the new scale
      stage.scale({ x: newScale, y: newScale });

      // Adjust the position to keep the zoom centered
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };

      stage.position(newPos);
      stage.batchDraw();

      // Enable dragging only if the stage is zoomed in or out (not at default scale)
      stage.draggable(newScale !== 1);
    });

    // Handle arrow key presses to drag the stage left and right
    const moveStageBy = 10; // The amount of pixels to move the stage by

    window.addEventListener('keydown', (e) => {
      // Only allow dragging with arrow keys if the stage is zoomed in or out
      if (stage.scaleX() === 1) return;

      switch (e.key) {
        case 'ArrowLeft':
          stage.x(stage.x() + moveStageBy); // Move stage to the right (because of the coordinate system)
          stage.batchDraw();
          break;
        case 'ArrowRight':
          stage.x(stage.x() - moveStageBy); // Move stage to the left
          stage.batchDraw();
          break;
        default:
          break;
      }
    });
  };

  //----------------- {konva}initializeStage when the image state changes --------------
  useEffect(() => {
    if (image) {
      initializeStage(image);
    }
    // Initialize with an empty array
  }, [image]);  // Initialize with an empty array

  useEffect(() => {
    initializeStage();
    return () => {
      if (layerRef?.current) layerRef?.current?.destroy();
      if (stageRef?.current) stageRef?.current?.destroy();
    };
    // eslint-disable-next-line
  }, []);

  //----------- function that are work for add icons to stage {konva function}--------------
  const addIconToStage = (src, x, y, width, height, zIndex) => {
    const image = new window.Image();
    image.onload = () => {
      const icon = new Konva.Image({
        image: image,
        x: x,
        y: y,
        width: width,
        height: height,
        draggable: true,
        zIndex: zIndex
      });
      icon.on('click', (e) => {
        selectElement(e.target);
      });
      layerRef.current.add(icon);
      layerRef.current.batchDraw();
    };
    image.src = src;
  };


  // ----------------------------------- konva End --------------------------------------------


  //------------------ save Api call function --------------------
  const handleButtonClick = () => {
    setLoaded(true);
    // Get all images and paths from the layer
    const icons = layerRef.current.getChildren(node => node.getClassName() === 'Image' || node.getClassName() === 'Path');
    const filteredIcons = icons.filter(icon => icon.getZIndex() !== 0);
    const positionsArray = filteredIcons.map(icon => {
      const iconClientRect = icon.getClientRect();
      return {
        x: icon.x(),
        y: icon.y(),
        width: iconClientRect.width,
        height: iconClientRect.height,
        path: icon.attrs.image.src.split('/').pop(),
        zIndex: icon.getZIndex(),
      };
    });

    const pagejson = (positionsArray);
    const token = localStorage.getItem('token');
    let data = {
      questionMarkData: {
        marks: score ? score : 0,
        barcodeId: barcodeId,
        questionId: qsenId ? qsenId : 0
      },
      answerSheetPageData: {
        imageName: pathname,
        examId: id,
        subjectId: subjectId,
        imageJson: pagejson,
        barcodeId: barcodeId,
        pageNo: currentIndex + 1
      }
    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/marks-page/save`,
      headers: {
        'Authorization': token,
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Marks saved successfully");
        pagesecton();
        setLoaded(false);
        setSelectedOptionpoint(0);
        setSelectedValue("");
        setFullMarks(0);
        setPointMarks(0);

      })
      .catch((error) => {
        console.log(error);
        setLoaded(false);
        toast.error(error.response.data.message);
      });
  };


  //------------------- image pagination(PreviousValue) -------------
  const showPreviousValue = () => {
    setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1));
  };

  //----------------- image pagination(NextValue)----------------
  const showNextValue = () => {
    setCurrentIndex(prevIndex => Math.min(pageValues.length - 1, prevIndex + 1));
  };
  //----------------------- Function to zoom in -----------------
  const zoomIn = () => {
    if (!stage) return; // Ensure stage is initialized
    const scaleBy = 1.1; // Zoom factor
    const oldScale = stage.scaleX();
    const newScale = oldScale * scaleBy;
    stage.draggable(true);
    // Set a maximum zoom level to prevent over-zooming
    if (newScale > 3) return; // Example max scale limit

    stage.scale({ x: newScale, y: newScale });
    setZoomLevel(newScale); // Update the slider

    // Adjust position to keep the stage centered
    const stageWidth = stage.width();
    const stageHeight = stage.height();
    const newPos = {
      x: (stageWidth - stageWidth * newScale) / 2,
      y: (stageHeight - stageHeight * newScale) / 2,
    };

    stage.position(newPos);
    stage.batchDraw(); // Efficient redraw
  };

  //--------------------------- Function to zoom out ------------------
  const zoomOut = () => {
    if (!stage) return; // Ensure stage is initialized
    const scaleBy = 1.1; // Zoom factor
    const oldScale = stage.scaleX();
    const newScale = oldScale / scaleBy;
    stage.draggable(true);
    // Set a minimum zoom level to prevent under-zooming
    if (newScale < 0.5) return; // Example min scale limit

    stage.scale({ x: newScale, y: newScale });
    setZoomLevel(newScale); // Update the slider

    // Adjust position to keep the stage centered
    const stageWidth = stage.width();
    const stageHeight = stage.height();
    const newPos = {
      x: (stageWidth - stageWidth * newScale) / 2,
      y: (stageHeight - stageHeight * newScale) / 2,
    };

    stage.position(newPos);
    stage.batchDraw(); // Efficient redraw
  };

  //--------------------------- Function to reset zoom to original state ------------------
  const resetZoom = () => {
    if (!stage) return;

    // Reset scale and position to default values
    stage.scale({ x: 1, y: 1 });
    stage.position({ x: 0, y: 0 });

    // Reset the zoom level state if you have a slider or other UI element
    setZoomLevel(1); // Reset the slider or any other zoom level indicator

    // Disable dragging when zoomed out to default level
    stage.draggable(false);

    // Redraw the stage
    stage.batchDraw();
  };

  //----------------- Function to handle slider changes ----------------
  const handleZoomChange = (event) => {
    if (!stage) return;
    const newScale = parseFloat(event.target.value);
    stage.scale({ x: newScale, y: newScale });
    setZoomLevel(newScale); // Update state

    // Center the stage content
    const stageWidth = stage.width();
    const stageHeight = stage.height();
    const newPos = {
      x: (stageWidth - stageWidth * newScale) / 2,
      y: (stageHeight - stageHeight * newScale) / 2,
    };
    stage.position(newPos);
    stage.batchDraw(); // Efficient redraw
  };

  //-------------- Add Checkmark icon in AnswerSheet ----------------------
  const handleAddCheckMark = () => {
    addIconToStage('/assets/img/chak.png');
  };

  //--------------------Add Cross icon in AnswerSheet -----------------------
  const handleAddCrossTick = () => {
    addIconToStage('/assets/img/cross.png');
  };

  //------------------ Add Duble slash icon in AnswerSheet -------------------
  const handleAddslash = () => {
    addIconToStage('/assets/img/double-slash.png');
  };

  //------------------------- Clean all icon in AnswerSheet -------------------
  const handleClean = () => {
    const img = layerRef?.current?.findOne('.img');
    layerRef?.current?.destroyChildren();
    if (img) {
      layerRef.current.add(img);
    }
    layerRef?.current?.batchDraw();
    const tr = new Konva.Transformer({
      rotateEnabled: false,
    });
    layerRef.current.add(tr);
    transformerRef.current = tr;
  };

  //--------------- Delete Element  in AnswerSheet ----------------
  const handleDelete = () => {
    const selectedElement = selectedElementRef.current;
    if (selectedElement) {
      selectedElement.remove();
      transformerRef.current.detach();
      layerRef.current.draw();
    }
  };

  //----------------------select Element sign in AnswerSheet ----------------
  const selectElement = (element) => {
    selectedElementRef.current = element;
    transformerRef.current.nodes([element]);
    const layer = layerRef.current;
    if (layer) {
      transformerRef.current.getLayer().batchDraw();
    }
    // Retrieve and log the selected element's width and height
    const elementWidth = element.width();
    const elementHeight = element.height();
    if (element.className === 'Text') {
      // If it's a Text element, set the input text to the element's current text
      // setInputText(element.text());
    } else {
      // For other elements, we can log or handle width/height in a different way
      // setInputText('');
    }
  };

  //---------------select Any icon second time click disable frame ---------------- 
  const deselectElement = () => {
    selectedElementRef.current = null;
    transformerRef.current.detach();
    layerRef.current.draw();

    transformerRef.current.nodes([]);
    layerRef.current.batchDraw();
  };

  //------------------- Qsn not attend to call this function -----------------
  const handleAddWarning = () => {
    const token = localStorage.getItem('token');
    let data = {
      questionMarkData: {
        barcodeId: barcodeId,
        questionId: qsenId
      }
    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/not-atepmted/qus`,
      headers: {
        'Authorization': token, // Replace 'YourAuthToken' with your actual token
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Qsn not attend save successfully");
        pagesecton();

      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });

  };

  //------------ back to AllocateSheets page ------------------- 
  const backtopage = () => {
    navigate(-1);
  };

  //------------- section table deta api call in  useEffect -----------
  useEffect(() => {
    pagesecton();

  }, [id, subjectId, apiUrl]);

  //------------- section table deta api -------------------  
  const pagesecton = () => {
    const token = localStorage.getItem('token');
    axios.get(`${apiUrl}/api/section/${id}/${subjectId}?barcodeId=${barcodeId}`, {
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        setJsonData(res.data)
      })
      .catch(err => console.error(err));
  }

  //-------------------------- Section table Define columns-----------------------
  const columns1 = [
    {
      name: "Status",
      selector: row => (
        <>
          {row.status ? <IoMdCheckmarkCircleOutline size={30} color="green" /> : null}
        </>
      ),
      center: "true",
      width: '65px',
    },

    {
      name: 'QSN',
      selector: row => (
        <button
          className={`btn btn-primary btn-sm  ${activeButtons[`QSN-${row.no}`] ? 'blink' : ''}`}
          onClick={() => handleClick(row, 'QSN')}
        >
          {row.no}
        </button>
      ),
      center: "true",
      width: '65px',
    },
    {
      name: 'ORQSN',
      selector: row => (
        <div className="container text-center">
          {row.text2 ? (
            <button
              className={`btn btn-primary btn-sm ${activeButtons[`ORQSN-${row.no}`] ? 'blink' : ''}`}
              onClick={() => handleClick(row, 'ORQSN')}
            >
              OR {row.no}
            </button>
          ) : null}
        </div>
      ),
      center: "true",
    },
    {
      name: 'Marks',
      selector: row => row.marks,
      center: "true",
      width: '70px',
    },
    {
      name: 'Score',
      selector: row => row.score,
      width: '60px',
      center: "true",

    },
  ];
  //----------------- Function to go to the next section  ----------
  const nextPage = () => {
    setCurrentSectionIndex((prevIndex) => Math.min(prevIndex + 1, jsonData.sections.length - 1));
    cleardata();
    setActiveButtons(false);
  };

  //------------------ Function to go to the previous section -----------
  const prevPage = () => {
    setCurrentSectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    cleardata();
    setActiveButtons(false);
  };

  //------------ Qsan text add to state deta and show athor table -------
  const qsantext = (row) => {
    setQsnNo(row.no)
    setQsnText(row.text)
    setEnable(false)
    setTotalMarks(row.marks)
    setQsenId(row.id)
  }

  //-------- Or qsan text add to state deta and show athor table ----------
  const qsantext2 = (row) => {
    setQsnNo(row.no)
    setQsnText(row.text2)
    setEnable(false)
    setTotalMarks(row.marks)
    setQsenId(row.id)
  }

  //-------- clear data using useeffect for clear data   
  useEffect(() => {
    if (clearTrigger) {
      setQsnNo('');
      setQsnText('');
      setEnable(true);
      setTotalMarks('');
      setQsenId('');
      setClearTrigger(false);
    }
  }, [clearTrigger]);

  //------- clear qsan text and data ---------
  const cleardata = () => {
    setClearTrigger(true);
  };

  //-----------------  Qsn click event  handler function ----------------
  const handleClick = (row, type) => {
    const key = `${type}-${row.no}`;
    setActiveButtons(prevState => {
      const newState = { ...prevState };
      const wasActive = prevState[key] === true;
      // If a button was clicked before, unclick it
      if (prevState.lastClicked && prevState.lastClicked !== key) {
        newState[prevState.lastClicked] = false;
      }
      newState[key] = !prevState[key];
      // Alert if the button is being deselected
      if (wasActive && !newState[key]) {
        cleardata();
      }
      return {
        ...newState,
        lastClicked: newState[key] ? key : prevState.lastClicked
      };
    });
    // Call functions based on the type and update the state
    if (type === 'QSN') {
      qsantext(row);
    } else if (type === 'ORQSN') {
      qsantext2(row);
    }
  };

  //------------drop down handle select full marks value -----------
  const handleChange = (event) => {
    const selected = parseInt(event.target.value);
    setSelectedValue(selected);
    if (selected == totalMarks) {
      setFullMarks(totalMarks);
      setPointMarks(0);
      setSelectEnable(true)
    }
    else {
      setSelectEnable(false)
    }
    setSelectedOptionpoint('');
    setPointMarks(0);
    setFullMarks(selected);
  };

  //----------- drop-down menu point value ------------
  const handleSelectChangepoin = (event) => {
    const selectedValue = parseFloat(event.target.value);
    setSelectedOptionpoint(selectedValue);
    setPointMarks(selectedValue);
  };


  //------------------- open modal------------
  const handleBarcodeClick = (row) => {
    setShowModal(true);
  };

  //------------------ close modal ------------------ 
  const closeModal = () => {
    setShowModal(false);
  };

  // ------------------ close model after submit----------------------
  const closemodel = () => {
    const closeButton = document.querySelector('#modal3 .btn-close');
    if (closeButton) {
      closeButton.click();
      setTimeout(() => navigate(-1), 3000);
    }
  };

  //------------------ show question paper --------------------------
  const openQuestionPaper = () => {
    const token = localStorage.getItem('token');
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/getQuestionPaperAndAnswerKey/${id}/${subjectId}`,
      headers: {
        'Authorization': `${token}`,
      }
    };

    setLoading(true);
    axios.request(config)
      .then((response) => {
        const byteCharacters = atob(response.data.questionPaperContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        setPdfBlob(URL.createObjectURL(blob));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching PDF:', error);
        setLoading(false);
      });
  };

  //-------------------------- show answer key pdf ---------------------
  const openAnswerSheet = () => {
    const token = localStorage.getItem('token');
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/getQuestionPaperAndAnswerKey/${id}/${subjectId}`,
      headers: {
        'Authorization': `${token}`,
      }
    };

    setLoading(true);
    axios.request(config)
      .then((response) => {
        const byteCharacters = atob(response.data.answerKeyContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        setAnswerKeyBlob(URL.createObjectURL(blob));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching PDF:', error);
        setLoading(false);
      });
  };
  //----------------------  final submit of answer sheet function ---------------------
  const finalSubmit = () => {
    const token = localStorage.getItem('token');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/submit/${barcodeId}`,
      headers: {
        'Authorization': `${token}`
      }
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Answer sheet submitted successfully");
        closemodel();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message || error.response.data.error || 'Error submitting the answer sheet because of some technical issue ');
      });
  }

  //--------------------- grand total table  api call -----------------
  const GrandTotalTable = () => {
    const token = localStorage.getItem('token');
    let data = {
      subjectId: subjectId,
      examId: id,
      barcodeId: barcodeId
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/answersheet/section/barcode/marks`,
      headers: {
        'Authorization': token, // Replace 'YourAuthToken' with your actual token
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Grand total marks calculated successfully");
        setGrandTotal(response.data.sections);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  }
  //  grandTotal columns 
  const grandTotalcolumns = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: '50px',
      center: "true",
    },
    {
      name: 'Section',
      selector: row => row.sectionName
    },
    {
      name: 'Total Questions',
      selector: row => row.questionCount,
    },
    {
      name: 'Chaked Questions',
      selector: row => row.checkQus,
    },
    {
      name: 'Total Marks',
      selector: row => row.totalMarks,
    },
    {
      name: 'Score',
      selector: row => row.score,
    },
  ];

  //------- this function is for delete button click in keyboard shortcut---------
  const handleKeyDown = (event) => {
    if (event.key === 'Delete') {
      handleDelete();
    }
  };

  useEffect(() => {
    // Add keydown event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (<>
    <main id="main" className="main" style={{ marginTop: '50px' }}>
      <HeaderAnswersheet Heading='Faculty' />
    </main>
    {/* main div  for holding konva and all the elements */}
    <div className='d-flex flex-row'>
      {/*------------------------------- left-tools ----------------------------------------*/}
      <div className='left-tools bg-white'>
          <button class="btn btn-secondary btn-sm round mt-2 m-1" data-tooltip-id="my-tooltip" data-tooltip-content=" Sheet Barcode" type="button">Actons</button>
        {/* Button to add checkmark */}
        <button type="button" className="btn btn-success mt-4 mb-4" onClick={handleAddCheckMark} disabled={enable} ><i className="bi bi-check-circle"></i></button>
        {/* Button to add cross */}
        <button type="button" className="btn btn-danger mb-4" onClick={handleAddCrossTick} disabled={enable}><i className="bi bi-x-circle"></i></button>
        {/* Button to add not attend  */}
        <button type="button" className="btn btn-warning mb-4" onClick={handleAddWarning} disabled={enable} > <i className="bi bi-exclamation-triangle"></i></button>
        {/* Button to add slash */}
        <button type="button" className="btn btn-primary mb-4" onClick={handleAddslash}  ><TbSlashes /></button>
        {/* button to clean */}
        <button type="button" className="btn btn-secondary mb-4" onClick={handleClean} ><MdCleaningServices /></button>
        {/* selected delete */}
        <button type="button" className="btn btn-danger mb-4" onClick={handleDelete}  ><i className="bi bi-trash3-fill"></i></button>
        {/* button to go back */}
        <button type="button" className="btn btn-dark  mb-4" onClick={backtopage}><i className="bi bi-arrow-bar-left"></i></button>
      </div>
      {/*-------------------------------- main-colum ---------------------------------------*/}
      <div className='main-colum '>
        <div className="card-body navigation-card ">
          <div class="d-flex justify-content-between ">
            <div class=" bd-highlight"><button type="button" className="btn btn-primary btn-sm mb-0  mt-1" onClick={showPreviousValue}><i className="bi bi-chevron-left"></i></button></div>
            <div class="p-2 bd-highlight">Total Page:{currentIndex + 1}/{pagecount}</div>
            <div class="mb-0 mt-1 bd-highlight"> <button type="button" className="btn btn-primary btn-sm mx-1" onClick={zoomIn} ><i className="bi bi-zoom-in"></i></button></div>
            <div class=" mb-0 mt-1 bd-highlight"> <button type="button" className="btn btn-primary btn-sm mx-1" onClick={zoomOut}><i className="bi bi-zoom-out"></i></button></div>
            <div class="p-2 bd-highlight"><label htmlFor="zoomSlider" style={{ marginRight: '-21px' }}>Zoom Level:</label></div>
            <div class="p-2 bd-highlight"><input
              data-tooltip-id="my-tooltip" data-tooltip-content="Zoom Level"
              type="range"
              min="0.5"
              max="3"
              step="0.1"
              value={zoomLevel}
              onChange={handleZoomChange}
            /></div>
            <div class="mb-0 mt-1 bd-highlight"><button type="button" className="btn btn-primary btn-sm mx-1" onClick={resetZoom} data-tooltip-id="my-tooltip" data-tooltip-content="Reset Zoom"><i className="bi bi-arrow-clockwise"></i></button></div>
            <div class=" bd-highlight"><button type="button" className="btn btn-primary btn-sm mt-1 " onClick={showNextValue}><i className="bi bi-chevron-right"></i></button></div>
          </div>
          <div className='position-loder'>
            {loading ? (
              // Show loader while loading
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              // Render your data here
              <div>
                {/* Render your image here */}
              </div>
            )}
          </div>
        </div>
        <div>
          <div ref={stageRef} id="container" className='Kanva-man '></div>
        </div>
      </div>

      {/*--------------------------------- right-tools ---------------------------------------*/}
      <div className='right-tools'>
        <div class="d-grid gap-2 mt-2 m-2 mb-4">
          <button class="btn btn-secondary round btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content=" Sheet Barcode" type="button"><i className="bi bi-upc-scan"></i> {barcode}</button>
        </div>

        {/*------------------------------ Question Detail ------------------------------*/}

        <div className=" m-2 costop-Table" >
          <label className='bold text-center h6'>Question Detail</label>
          <div className="d-flex flex-row mb-3">
            <div className="p-2  col-lg-2 ">
              <input type="text" class="form-control text-center cursor-pointer" value={data} disabled />
            </div>
            <div className="p-2 col-lg-8 "  >
              <input type="text" onClick={() => handleBarcodeClick()} class="form-control text-truncate  cursor-pointer " value={qsnText} disabled={enable} />
            </div>

            <div className=" mt-2">
              <button onClick={() => openQuestionPaper()} className="btn btn-primary btn-sm mt-1" data-tooltip-id="my-tooltip" data-tooltip-content="View Question Paper" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i className="bi bi-file-pdf-fill"></i></button>
            </div>

            <div className="ms-1 mt-2">
              <button onClick={() => openAnswerSheet()} className="btn btn-primary btn-sm mt-1" data-bs-toggle="modal" data-bs-target="#modal2" data-tooltip-id="my-tooltip" data-tooltip-content="View Answer Key"> <i className="bi bi-file-text"></i></button>
            </div>
          </div>
        </div>

        {/*--------------------------------- section table ------------------------------*/}
        <div className="mt-1" >
          <div class="d-flex justify-content-between">
            <div class="p-1 bd-highlight"><label className='bold me-3'>Section:-{sectionName}</label></div>
            <div class="p-1 bd-highlight"><label className='bold '>Score:-21/25</label></div>
            <div class="p-1 bd-highlight"><h6><span class="badge bg-success "><i className="bi bi-award"></i> Pass</span></h6></div>
          </div>
          <DataTable
            columns={columns1}
            data={section}
            className="custom-table"
          />
          {/*------------ selected  footer button ------------------------ */}
          <div className="text-center table-btn-footer mt-2">
            <div className="d-flex justify-content-between mb-3">
              <div className="p-1 bd-highlight"><button className='btn btn-secondary btn-sm' onClick={prevPage} disabled={currentSectionIndex === 0}><i className="bi bi-caret-left-fill"></i></button></div>
              <div className="p-1 bd-highlight"> <button className='btn btn-secondary btn-sm' onClick={nextPage} disabled={currentSectionIndex === jsonData.sections.length - 1}><i className="bi bi-caret-right-fill"></i></button></div>
            </div>
          </div>
          {/*--------------------------------- drop-down ------------------------------ */}
          <div class="d-flex p-2 mt-2">
            <select
              value={selectedValue}
              onChange={handleChange}
              className="form-select mx-1"
            >
              <option value=""  >
                Select Mark
              </option>
              {options.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <select
              value={selectEnable ? "" : selectedOptionpoint}
              onChange={handleSelectChangepoin}
              className="form-select mx-1 "
              aria-label="Default select example"
              disabled={selectEnable}
            >
              <option value="0">Select Mark</option>
              <option value="0.25">0.25</option>
              <option value="0.5">0.5</option>
              <option value="0.75">0.75</option>
            </select>
          </div>
        </div>
        {/*----------------------- button area for modal  submiot -----------------------*/}
        <div class="d-flex justify-content-center mt-4">
          <div class="p-2 bd-highlight"><button
            type="button"
            data-tooltip-id="my-tooltip" data-tooltip-content="Save Qsan Marks"
            className="btn btn-primary px-4"
            onClick={handleButtonClick}
            disabled={loaded}
          >
            {loaded ? (
              <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"> </span>
            ) : (
              <>
                <i className="bi bi-bookmark-fill"></i> Save
              </>
            )}
          </button></div>
          <div class="p-2 bd-highlight"><button type="button" className="btn btn-success px-5" data-bs-toggle="modal" data-bs-target="#modal4" onClick={GrandTotalTable} data-tooltip-id="my-tooltip" data-tooltip-content="Grand Total & final submit"><i className="bi bi-ui-checks"></i> Final Submit</button></div>

        </div>
      </div>
    </div>

    {/*-------------------------------- page modal area----------------------------------------- */}
    {/*--------------- this is daylog box for view Question full length --------------------------*/}
    {showModal && (
      <div
        className="modal fade show d-block"
        tabIndex="-1"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Question</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {data.text} {qsnText}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>

      </div>
    )}

    {/*---------------- this is daylog box for view question paper---------------------------------*/}
    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel"></h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

            {loading && <div>Loading...</div>}
            {pdfBlob && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div style={{ height: '500px' }}>
                  <Viewer fileUrl={pdfBlob} />
                </div>
              </Worker>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    {/*------------------------ this is daylog box for view answer key ----------------------------*/}
    <div className="modal fade" id="modal2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" maria-labelledby="modal2Label" aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal2Label">Answer Key</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {answerKeyBlob && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div style={{ height: '500px' }} className="pdf">
                  <Viewer fileUrl={answerKeyBlob} />
                </div>
              </Worker>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    {/*---------------------------------- this is daylog box for  grand total table & final submit ---------------------*/}
    <div
      className="modal fade"
      id="modal4"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="modal3Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal3Label">Grand Total</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <div class="d-flex flex-row-reverse">
              <div class="p-2"><h5><span class="badge bg-success mt-1"><i className="bi bi-award"></i> Pass</span></h5></div>
              <div class="p-2"><label className='bold mt-1'>Score:-21/25</label></div>
            </div>
            <DataTable
              columns={grandTotalcolumns}
              data={grandTotal}
              className="custom-table"
            />

          </div>
          <div className="modal-footer d flex justify-content-center">
            <button type="button" className="btn btn-success" onClick={finalSubmit} ><i className="bi bi-check2-square" ></i> Final Submit</button>
            <button type="button" className="btn btn-secondary px-4" data-bs-dismiss="modal"> <i className="bi bi-x-circle"></i> Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <Tooltip id="my-tooltip" place="bottom" />
    <ToastContainer />
  </>
  );
}