import React, { useEffect, useState } from "react";
import Layout from "../Componets/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function AddAdmins() {
  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false); // save button  loading  efect deta  hold state 

  // Check if role is retrieved correctly
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'SUPER_ADMIN') {
      navigate("/");
    }
  }, [navigate]);

  // State to hold records of clients
  const [records, setRecords] = useState([]);
  // Fetch list of clients from API when component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${apiUrl}/api/client`, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then(res => {
        setRecords(res.data.clients);
      })
      .catch(err => console.log(err));
    // eslint-disable-next-line
  }, []);

  // State to hold form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNo: '',
    address: '',
    status: true,
    username: '',
    password: '',
    role: 'ADMIN',
    remarks: '',
    clientId: ''
  });

  // State to track password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to handle form submission
  const handleSubmit = (e) => {
    setLoaded(true);
    e.preventDefault();
    const token = localStorage.getItem('token');
    const parsedClientId = parseInt(formData.clientId);
    axios.post(`${apiUrl}/api/admin`, { ...formData, clientId: parsedClientId }, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then(res => {
        setLoaded(false);
        console.log("Admin added successfully:", res.data);
        toast.success("Admin added successfully");
        setTimeout(() => navigate('/ViewAdmins'), 3000);

        // Reset form data to initial state
        setFormData({
          name: '',
          email: '',
          contactNo: '',
          address: '',
          status: true,
          username: '',
          password: '',
          role: 'ADMIN',
          remarks: '',
          clientId: ''
        }); // Clear form data after successful submission
      })
      .catch(err => {
        setLoaded(false);
        console.error("Error adding admin:", err);
        toast.error(err.response.data.message);

      });
  }

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'pincode' ? parseInt(value) || 0 : value
    }));
  }

  // Function to handle client selection from dropdown
  const handleClientChange = (e) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      clientId: value
    }));
  }

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  return (
    <>
      <Layout>
        <main id="main" className="main">
          <div className="pagetitle ">

            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item "><Link to="/Home">Dashboard</Link></li>
                <li className="breadcrumb-item active">Add New Admins</li>
              </ol>
            </nav>
          </div>
          {/* <!-- End Page Title --> */}

          <section className="section col-lg-12">
            <div className="row">
              <div className="">
                <div className="card">
                  <div className="card-body mt-5  mx-3">


                    {/* <!-- General Form Elements --> */}
                    <form onSubmit={handleSubmit}>
                      {/* Details of admin */}
                      <div className="container">
                        <div className="row row-cols-2">
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
                              <label className="form-label" htmlFor="txtGrNo">Name <span className="text-danger">*</span></label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                              <label className="form-label" htmlFor="txtGrNo">Email <span className="text-danger">*</span></label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type="text" name="contactNo" value={formData.contactNo} onChange={handleChange} placeholder="Contact No" required />
                              <label className="form-label" htmlFor="txtGrNo">Contact No <span className="text-danger">*</span></label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
                              <label className="form-label" htmlFor="txtGrNo">Address <span className="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Other details */}
                      <div className="container">
                        <div className="row row-cols-2">
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type="text" name="username" value={formData.username} onChange={handleChange} placeholder="Username" required />
                              <label className="form-label" htmlFor="txtGrNo">Username <span className="text-danger">*</span></label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-2">
                              <input className="form-control" type={showPassword ? 'text' : 'password'} name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />
                              <label className="form-label" htmlFor="txtGrNo">Password <span className="text-danger">*</span></label>
                              <button
                                className="btn btn mt-3"
                                type="button"
                                onClick={togglePasswordVisibility}
                                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                              >
                                {showPassword ? (
                                  <i className="bi bi-eye-slash"></i>
                                ) : (
                                  <i className="bi bi-eye-fill"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>



                      {/* Select Client */}
                      <div className="container ">
                        <div className="row row-cols-2">
                          <div className="col">
                            <div className="form-floating mb-2">
                              <select className="form-select" value={formData.clientId} onChange={handleClientChange}>
                                <option value="">Select Client</option>
                                {/* Dynamically render options */}
                                {records.map(client => (
                                  <option key={client.id} value={client.id}>{client.name}</option>
                                ))}
                              </select>
                              <label className="form-label" htmlFor="txtGrNo">Client Name <span className="text-danger">*</span></label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-floating mb-2 ">
                              <input className="form-control" type="text" name="remarks" value={formData.remarks} onChange={handleChange} placeholder="Remarks" />
                              <label className="form-label" htmlFor="txtGrNo">Remarks</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="row mb-3">
                        <div className="col-sm-12 pt-4 text-end">
                          <button type="button" className="btn btn-primary px-3" onClick={() => navigate(-1)}><i className="bi bi-arrow-bar-left"></i>Back</button>

                          <button
                            type="submit"
                            className="btn btn-primary  px-4 mx-2"
                            disabled={loaded}
                          >
                            {loaded ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Wait...</>
                            ) : (
                              <>
                                Save
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <!-- End General Form Elements --> */}
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </section >
        </main >
      </Layout >
    </>
  );
}
