import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Layout from '../Componets/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ViewAdmins() {
    const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file
    const navigate = useNavigate(); // Hook to navigate programmatically
    const [records, setRecords] = useState([]); // State to hold admin records
    const [clients, setClients] = useState([]); // State to hold client records
    const [searchTerm, setSearchTerm] = useState(''); // State to hold search term
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [totalRows, setTotalRows] = useState(0); // State to hold total number of rows
    const [perPage, setPerPage] = useState(10); // State to hold number of rows per page
    const [loadingMap, setLoadingMap] = useState({}); // State to manage loading state for each admin button

    // Check role and redirect to login page if not SUPER_ADMIN
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'SUPER_ADMIN') {
            navigate("/");
        }
    }, [navigate]);

    // Fetch admins and clients when the component mounts or when perPage changes
    useEffect(() => {
        fetchAdmins(1, perPage);
        fetchClients();
        // eslint-disable-next-line
    }, [perPage]);

    // Function to fetch admin records with pagination
    const fetchAdmins = async (page, perPage) => {
        setLoading(true); // Set loading state to true
        const token = localStorage.getItem('token'); // Get token from local storage
        try {
            const response = await axios.get(`${apiUrl}/api/admin?skip=${(page - 1) * perPage}&take=${perPage}`, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            setRecords(response.data.admins); // Set fetched admin records to state
            setTotalRows(response.data.count); // Set total number of rows
        } catch (error) {
            console.error('Error fetching admins:', error); // Log error if any
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    // Function to fetch client records
    const fetchClients = async () => {
        const token = localStorage.getItem('token'); // Get token from local storage
        try {
            const response = await axios.get(`${apiUrl}/api/client`, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            setClients(response.data.clients); // Set fetched client records to state
        } catch (error) {
            console.error('Error fetching clients:', error); // Log error if any
        }
    };

    // Function to handle page change in DataTable
    const handlePageChange = page => {
        fetchAdmins(page, perPage);
    };

    // Function to handle change in rows per page in DataTable
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage); // Update perPage state
        fetchAdmins(page, newPerPage); // Fetch admins with new perPage value
    };

    // Function to toggle admin status
    const toggleStatus = (id) => {
        setLoadingMap(prevLoadingMap => ({
            ...prevLoadingMap,
            [id]: true // Set loading state for the clicked button to true
        }));

        const token = localStorage.getItem('token'); // Get token from local storage

        axios.patch(`${apiUrl}/api/admin/${id}`, {
            status: !getStatus(id) // Toggle the status
        }, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                // Update status of admin in state after successful toggle
                setRecords(records.map(record => {
                    if (record.id === id) {
                        return { ...record, status: res.data.status };
                    }
                    return record;
                }));
                // Show notification based on status change
                setTimeout(() => {
                    if (res.data.status) {
                        toast.success("Admin activated successfully");
                    } else {
                        toast.error("Admin deactivated successfully");
                    }
                }, 2000);

                // Set loading state for the clicked button to false
            })
            .catch(err => console.error('Error toggling status:', err))
            .finally(() => {
                // Ensure loading state persists for at least 2 seconds
                setTimeout(() => {
                    setLoadingMap(prevLoadingMap => ({
                        ...prevLoadingMap,
                        [id]: false // Set loading state for the clicked button to false
                    }));
                }, 2000); // 2000 milliseconds (2 seconds)
            });
    };

    // Function to get status of admin by id
    const getStatus = (id) => {
        const record = records.find(record => record.id === id);
        return record ? record.status : false; // Return status or false if not found
    };

    // Function to handle admin deletion
    const handleDelete = (id) => {

        if (!window.confirm('Are you sure you want to delete this Admin?')) return; // Confirmation dialog

        const token = localStorage.getItem('token'); // Get token from local storage

        axios.delete(`${apiUrl}/api/admin/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then(res => {
                // Remove deleted admin from records array in state
                setRecords(records.filter(record => record.id !== id));
                toast.success("Admin deleted successfully");
            })
            .catch(err => {
                toast.error(err.response.data.message); // Show error message
                console.error('Error deleting admin:', err); // Log error
            });
    };

    // Function to get client name by clientId
    const getClientName = (clientId) => {
        const client = clients.find(client => client.id === clientId);
        return client ? client.name : 'Unknown'; // Return client name or 'Unknown' if not found
    };

    // Define table columns
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width: '50px',
            center: "true"
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: '120px'
        },
        {
            name: "Username",
            selector: (row) => row.username,
            sortable: true,
            width: '120px'
        },
        {
            name: "Contact Number",
            selector: (row) => row.contactNo,
            sortable: true
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            width: '140px'
        },
        {
            name: "Client Name",
            selector: (row) => getClientName(row.clientId), // Fetch client name by clientId
            sortable: true,
            width: '140px'
        },
        {
            name: "Remarks",
            selector: (row) => row.remarks,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Actions',
            width: '130px',
            center: "true",
            cell: (row) => (
                <div>
                    {/* Button to toggle status */}
                    <button className={`btn btn-${getStatus(row.id) ? 'success' : 'danger'} btn-sm`} onClick={() => toggleStatus(row.id)} disabled={loadingMap[row.id]}>
                        {loadingMap[row.id] ? (
                            <i className="spinner-border spinner-border-sm" role="status"></i>
                        ) : (
                            getStatus(row.id) ? <i className="bi bi-check-circle"></i> : <i className="bi bi-x-circle"></i>
                        )}
                    </button>

                    {/* Button to edit admin */}
                    <button type="button" className="btn btn-primary btn-sm mx-2" onClick={() => navigate('/EditAdmin', { state: { id: row.id } })}><i className="bi bi-pencil-square"></i></button>

                    {/* Button to delete admin */}
                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => handleDelete(row.id)} disabled={loadingMap[row.id]}><i className="bi bi-trash3-fill"></i></button>
                </div>
            )
        }
    ];

    return (
        <div>
            <main id="main" className="main">
                <Layout>
                    <div className="pagetitle">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/Home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">View Admins</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='container mt-2'>
                        <div className="row">
                            <div className="col-lg-15">
                                <div className="card">
                                    <div className="card-body mt-4 mb-1 mx-3">
                                        <div className="datatable-top">
                                            <div className="datatable-search">
                                                {/* Input field for search */}
                                                <div className="datatable-search">
                                                    <input className="form-control" type="search" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                                </div>
                                            </div>
                                            <div>
                                                {/* Button to add new admin */}
                                                <button type="button" className="btn btn-primary" onClick={() => navigate('/AddAdmins')}><i className="bi bi-person-fill-add"></i> Add Admin</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            {/* DataTable component with search functionality */}
                                            <DataTable
                                                columns={columns}
                                                data={records.filter(record =>
                                                    (record.name && record.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                                    (record.username && record.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                                    (record.contactNo && record.contactNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                                    (record.email && record.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                                    (record.remarks && record.remarks.toLowerCase().includes(searchTerm.toLowerCase()))
                                                )}
                                                progressPending={loading} // Show loading indicator
                                                pagination // Enable pagination
                                                paginationServer // Enable server-side pagination
                                                paginationTotalRows={totalRows} // Set total number of rows
                                                paginationPerPage={perPage} // Set number of rows per page
                                                onChangeRowsPerPage={handlePerRowsChange} // Handle change in rows per page
                                                onChangePage={handlePageChange} // Handle page change
                                                className="custom-table"
                                            />
                                            {/* Notification container */}
                                            <ToastContainer />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Layout>
            </main>
        </div>
    );
}
