
import Layout from "../Componets/Layout";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function Home(p) {
  const navigate = useNavigate(); // Use navigate hook from react-router-dom

  // check  SUPER_ADMIN is logged in or not
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'SUPER_ADMIN') { // Adjusted comparison to match the role stored in localStorage
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  //  alert function to show alert when login successfully
  useEffect(() => {
    if (!localStorage.getItem('alertShown')) {
      // Show the alert 
      toast.success("Login successfully");
      // Set a flag in local storage to indicate that the alert has been shown
      localStorage.setItem('alertShown', 'true');
    }
  }, []);




  return (

    <Layout >
      <main id="main" className="main">
        <div className="pagetitle mb-4 ">
          <h1>Dashboard</h1>

        </div>

        {/* ------------------------------------------Page Title ---------------------------- */}

        <section className="section dashboard mx-5">
          <div className="row">
            {/* ----------------------------- Sales Card --------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/ViewClient")}
                  >
                    Manage Clients
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center mx-4">
                      <i className="bi bi-person-fill-gear"></i>
                    </div>
                    <div className="ps-0">
                      <button type="button" className="btn btn-primary mx-2" onClick={() => navigate("/AddClients")}><i className="bi bi-plus-circle"></i> Add</button>
                      <button type="button" className="btn btn-success" onClick={() => navigate("/ViewClient")}><i className="bi bi-eye"></i> View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* ------------------------------ manage admins -------------------------------------- */}
            <div className="col-xxl-4 col-md-5">
              <div className="card info-card revenue-card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={() => navigate("/ViewAdmins")}
                  >
                    Manage Admins
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center mx-4">
                      <i className="bi bi-person-fill-add"></i>
                    </div>
                    <div className="ps-0">
                      <button type="button" className="btn btn-primary mx-2" onClick={() => navigate("/AddAdmins")}><i className="bi bi-plus-circle"></i> Add</button>
                      <button type="button" className="btn btn-success" onClick={() => navigate("/ViewAdmins")}><i className="bi bi-eye"></i> View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          limit={1} />
      </main>
      
    </Layout>
  );
}
